import EventEmitter from "./EventEmitter";

export default class Mouse extends EventEmitter {
  constructor() {
    super();
    this.x = 0;
    this.y = 0;

    // event listener
    window.addEventListener('mousemove', (e) => {
      this.x = e.clientX;
      this.y = e.clientY;

      this.trigger('mousemove');
    })
  }

}