<template>
  <section ref="sectionRef" id="headerSection" class="header">
    <HeaderCanvas
      :resources="resources"
      :percent="percent"
      :modelName="modelName"
    />
    <h1 ref="titleRef" class="">{{ title }}</h1>
    <h4 ref="subtitleRef" class="">{{ type }}</h4>
  </section>
  <ScrollDown targetID="#goalSection" :section="section" />
</template>

<script>
import HeaderCanvas from "@/components/headerCanvas/HeaderCanvas.vue";
import ScrollDown from "@/components/utilities/ScrollDown.vue";
import { onMounted, watchEffect, ref } from "@vue/runtime-core";
export default {
  props: {
    title: String,
    type: String,
    image: String,
    resources: Object,
    percent: Number,
    modelName: String,
    scrollY: Number,
    sizes: Object,
  },
  components: {
    HeaderCanvas,
    ScrollDown,
  },
  setup(props) {
    const sectionRef = ref();
    const section = ref(0);
    let oldSection = 0;

    const titleRef = ref();
    const subtitleRef = ref();

    onMounted(() => {
      // preset on mounted
      oldSection = section.value;

      // css property
      const elemCSS = window.getComputedStyle(sectionRef.value);

      watchEffect(() => {
        // prevent undefined value
        if (props.scrollY && props.sizes) {
          // section element properties
          const clientHeight = sectionRef.value.clientHeight;
          const offsetTop =
            sectionRef.value.offsetTop - parseInt(elemCSS.marginTop);
          const offsetBottom =
            sectionRef.value.offsetTop +
            clientHeight +
            parseInt(elemCSS.marginBottom);
          const sectionCenter = (offsetBottom - offsetTop) / 2;

          // test section's center is within bounds
          if (
            offsetTop + sectionCenter > props.scrollY &&
            offsetTop + sectionCenter < props.scrollY + props.sizes.height
          ) {
            section.value = 0;
          } else {
            // console.log("header is NOT in view");
            section.value = -1;
          }

          if (oldSection != section.value) {
            // assign new old section
            oldSection = section.value;

            if (section.value === 0) {
              // console.log("header is in view");
              titleRef.value.classList.remove("anim-opacity-hide");
              titleRef.value.classList.add("anim-opacity-show");
              subtitleRef.value.classList.remove("anim-opacity-hide");
              subtitleRef.value.classList.add("anim-opacity-show");
            } else {
              // console.log("header is NOT in view");
              titleRef.value.classList.remove("anim-opacity-show");
              titleRef.value.classList.add("anim-opacity-hide");
              subtitleRef.value.classList.remove("anim-opacity-show");
              subtitleRef.value.classList.add("anim-opacity-hide");
            }
          }
        }
      });
    });
    return { sectionRef, section, titleRef, subtitleRef };
  },
};
</script>

<style lang='scss'>
.header {
  &::after {
    content: "";
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    // background: rgb(107, 101, 101);
    // mix-blend-mode: overlay;
  }

  @include size(100%, 100vh);
  @include bgImg();
  @include flex-layout(column, center, center);

  h1 {
    color: $color-font-primary;
    font-size: 10vw;
  }

  h4 {
    color: $color-font-primary;
    font-size: 2.8vw;
    font-family: "Manrope", sans-serif;
    font-weight: 300;
  }
}

@media screen and (min-width: 414px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1920px) {
}
</style>