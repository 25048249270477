import * as THREE from 'three';

export default class ParticleSystem {
  constructor(scene, time) {
    this.scene = scene;
    this.time = time;
    this.particleCount = 1000;
    this.particleSize = 50;

    this.setParticles();
  }

  setParticles() {
    this.createGeometry();
    this.createMaterial();

    // particles instance
    this.instance = new THREE.Points(this.geometry, this.material);

    // offset by 6 units
    this.instance.position.y -= 6;

    // add to scene
    this.scene.add(this.instance);
  }

  createGeometry() {
    // create the geometry
    this.geometry = new THREE.BufferGeometry();

    // attributes 
    const positionArray = new Float32Array(this.particleCount * 3);
    const scaleArray = new Float32Array(this.particleCount);

    // area in which particles are spawned
    const particlesAreaBounds = new THREE.Vector3(50, 6 * 10, 50);

    // populate the vertices' position
    for (let i = 0; i < this.particleCount; i++) {
      // x position
      positionArray[i * 3 + 0] = (Math.random() - 0.5) * (particlesAreaBounds.x / 2);
      // y position
      positionArray[i * 3 + 1] = Math.random() * particlesAreaBounds.y;
      // y position
      positionArray[i * 3 + 2] = (Math.random() - 0.5) * (particlesAreaBounds.z / 2);

      // set random scale size
      scaleArray[i] = Math.random();
    }

    // set attributes 
    this.geometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3));
    this.geometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1));
  }

  createMaterial() {

    // vertex shader code
    this.vertexShader = `
      uniform float uTime;
      uniform float uPixelRatio;
      uniform float uSize;
      
      attribute float aScale;
      
      void main(){
        float speed = 0.001;
        float xFrqOffsetMult = 2.0;
        float yAmpOffMult = 0.5;
      
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;
      
        gl_Position = projectionPosition;
        gl_PointSize = uSize * aScale * uPixelRatio;
        // size attenuation 
        gl_PointSize *= (1.0 / -viewPosition.z);
      }
    `;


    // fragment shader code
    this.fragmentShader = `
      void main(){
  
        // float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
        // float strength = 0.05 / distanceToCenter - 0.05 * 2.0;
        
        gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
      }
    `;


    // create the material 
    this.material = new THREE.ShaderMaterial({
      // uniforms
      uniforms: {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: this.particleSize }
      },
      vertexShader: this.vertexShader,
      fragmentShader: this.fragmentShader,
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false
    })
  }

  dispose() {
    this.material.dispose();
    this.geometry.dispose();
  }


  resize() {
    this.instance.material.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2);
  }

  update() {
    this.instance.material.uniforms.uTime.value = this.time.elapsed;
  }
}