<template>
  <canvas ref="canvasEl" class="canvas"></canvas>
</template>

<script>
import { onMounted, onUnmounted, ref, watchEffect } from "vue";
// import script
import HomeWorld from "@/webgl/homeView/HomeWorld.js";
import ModelController from "@/webgl/homeView/ModelController.js";
import ScrollY from "@/webgl/utils/ScrollY.js";
export default {
  props: {
    resources: Object,
    percent: Number,
    section: Number,
  },
  setup(props) {
    // reference to canvas element
    const canvasEl = ref();

    const scrollY = new ScrollY();

    // create world
    const world = new HomeWorld();
    let modelController = null;

    let isResourcesLoaded = false;

    onMounted(() => {
      // set canvas
      world.canvas = canvasEl.value;

      // watch percent prop
      watchEffect(() => {
        if (props.percent === 100 && !isResourcesLoaded) {
          // set resources
          world.resources = props.resources;
          // init the world
          world.init();

          // create modelcontoller
          modelController = new ModelController(
            world.time,
            world.sizes,
            world.camera,
            scrollY,
            world.models
          );
          // initialize modelcontroller
          modelController.init();

          // set state
          isResourcesLoaded = true;
        }
      });
    });

    onUnmounted(() => {
      world.dispose();
      scrollY.enable = false;
      modelController.enable = false;
    });

    return { canvasEl };
  },
};
</script>

<style lang='scss'>
.canvas {
  @include size(100%, 100vh);
  @include fixed(0, 0);
  outline: none;
  z-index: -1;
}
</style>