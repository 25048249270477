<template>
  <section ref="sectionRef" class="about anim-opacity-hide">
    <h2 class="underline">About</h2>

    <div class="aspectRatio imageWrapper">
      <img src="@/assets/photo.jpg" alt="logo" />
      <!-- <img :src="require(`@/assets/work1/${imgResult}`)" alt="" /> -->
    </div>

    <p class="p1">
      Gerald Alvarez is a Montreal based multimedia developer with the intention
      of creating meaningful and playful projects. He likes to work with
      tangible and digital media, and tinker with diverse technologies.
      <br /><br />

      He studied in Interactive Media Arts, an ALC pre-university program at
      Dawson College. Then, he enrolled in Computation Art Specialization at
      Concordia University. In 2020, he graduated with a Bachelor of Fine Arts
      in Computation Art Specialization. Now, he seeks to work with
      multidisciplinary designers and multimedia companies, and is eager to
      learn new things.
    </p>

    <div class="contactMail">
      <h5 class="gmail">gen.alv14@gmail.com</h5>
    </div>
    <div class="checkOut">
      <h5>
        <a
          href="https://drive.google.com/file/d/1ui_qFuy9-a6A_78xXG1E-LP0qhmVtF0X/view?usp=sharing"
          target="_blank"
        >
          cv/resumé
        </a>
      </h5>
    </div>
    <ul class="mediaList">
      <li>
        <h5>
          <a
            href="https://www.linkedin.com/in/gerald-alvarez-553499178/"
            target="_blank"
          >
            <i class="fab fa-linkedin"></i>
          </a>
        </h5>
      </li>
      <li>
        <h5>
          <a href="https://twitter.com/genalv14" target="_blank">
            <i class="fab fa-twitter"></i>
          </a>
        </h5>
      </li>
    </ul>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  setup() {
    const sectionRef = ref();

    onMounted(() => {
      sectionRef.value.classList.remove("anim-opacity-hide");
      sectionRef.value.classList.add("anim-opacity-show");
    });

    return {
      sectionRef,
    };
  },
};
</script>

<style lang="scss">
.about {
  min-height: 100vh;
  @include size(100%, auto);
  @include flex-layout(column, center, flex-start);

  padding: 0 5vw;
  padding-top: 3rem;

  h2 {
    font-size: 2.5rem;
    color: $color-font-primary;
    margin: 0.5rem 1.6rem;
  }

  .imageWrapper {
    width: 100%;
    margin: 0.5rem 0;
  }

  p {
    color: $color-font-primary;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0.5rem 0;
  }

  .contactMail {
    margin: 0.5rem 0;

    h5 {
      color: $color-highlight-primary;
      font-size: 1rem;
      margin: 0;
    }
  }

  .checkOut {
    margin: 0.5rem 0;

    h5 {
      font-size: 1rem;

      a {
        color: $color-highlight-primary;
        margin: 0;

        &.router-link-exact-active {
          color: $color-highlight-primary;
        }

        &:hover {
          color: $color-highlight-primary;
        }
      }
    }
  }

  .mediaList {
    @include flex-layout(row, center, flex-start);
    margin: 0.5rem 0;
    li {
      h5 {
        color: $color-highlight-primary;
        font-size: 1rem;
      }

      padding-right: 1rem;
    }
  }
}

@media screen and (min-width: 414px) {
  .about {
    padding: 3rem 5vw;
  }
}
@media screen and (min-width: 600px) {
  .about {
    @include grid-layout(9, 5);

    h2 {
      @include grid-item(1, 6, 1, 2);
      @include grid-self(end, start);
    }

    .imageWrapper {
      @include grid-item(7, 10, 2, 5);
      @include grid-self(start, center);
    }

    p {
      @include grid-item(1, 7, 2, 5);
      margin-right: 3rem;
    }

    .contactMail {
      display: block;
      @include grid-item(1, 4, 5, 6);
      @include grid-self(center, start);
    }

    .checkOut {
      display: block;
      @include grid-item(4, 7, 5, 6);
      @include grid-self(center, center);
    }

    .mediaList {
      @include grid-item(7, 10, 5, 6);
      @include grid-self(center, end);
    }
  }
}
@media screen and (min-width: 768px) {
  .about {
    padding: 3rem 10vw;
  }
}
@media screen and (min-width: 962px) {
}
@media screen and (min-width: 1366px) {
  .about {
    h2 {
      font-size: 4rem;
      margin: 0.5rem 2rem;
    }

    p {
      font-size: 1.4rem;
    }

    .contactMail {
      h5 {
        font-size: 1.4rem;
      }
    }

    .checkOut {
      h5 {
        font-size: 1.4rem;
      }
    }

    .mediaList {
      li {
        h5 {
          font-size: 1.4rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1920px) {
}
</style>
