<template>
  <section ref="sectionRef" id="prevnextSection" class="prevnext">
    <h5 ref="titleRef" class="anim-opacity-hide">Other Projects</h5>
    <div ref="prevRef" class="prev anim-opacity-hide" @click="clickHandle">
      <h4>
        <router-link :to="{ name: prev.viewLink }">
          <i class="fas fa-chevron-left"></i>
          {{ prev.title }}
        </router-link>
      </h4>
    </div>
    <div ref="nextRef" class="next anim-opacity-hide" @click="clickHandle">
      <h4>
        <router-link :to="{ name: next.viewLink }">
          {{ next.title }}
          <i class="fas fa-chevron-right"></i>
        </router-link>
      </h4>
    </div>
  </section>
</template>

<script>
import { onMounted, watchEffect, ref } from "@vue/runtime-core";
export default {
  props: {
    prev: Object,
    next: Object,
    scrollY: Number,
    sizes: Object,
  },
  emit: ["clickHandle"],
  setup(props, { emit }) {
    const sectionRef = ref();
    const section = ref(-1);
    let oldSection = -1;

    const titleRef = ref();
    const prevRef = ref();
    const nextRef = ref();

    onMounted(() => {
      // preset on mounted
      oldSection = section.value;
      // css property
      const elemCSS = window.getComputedStyle(sectionRef.value);

      watchEffect(() => {
        // prevent undefined value
        if (props.scrollY && props.sizes) {
          // section element properties
          const clientHeight = sectionRef.value.clientHeight;
          const offsetTop =
            sectionRef.value.offsetTop - parseInt(elemCSS.marginTop);
          const offsetBottom =
            sectionRef.value.offsetTop +
            clientHeight +
            parseInt(elemCSS.marginBottom);
          const sectionCenter = (offsetBottom - offsetTop) / 2;

          // test section's center is within bounds
          if (
            offsetTop + sectionCenter > props.scrollY &&
            offsetTop + sectionCenter < props.scrollY + props.sizes.height
          ) {
            section.value = 0;
          } else {
            // console.log("header is NOT in view");
            section.value = -1;
          }

          if (oldSection != section.value) {
            // assign new old section
            oldSection = section.value;

            if (section.value === 0) {
              titleRef.value.classList.remove("anim-opacity-hide");
              titleRef.value.classList.add("anim-opacity-show");
              prevRef.value.classList.remove("anim-opacity-hide");
              prevRef.value.classList.add("anim-opacity-show");
              nextRef.value.classList.remove("anim-opacity-hide");
              nextRef.value.classList.add("anim-opacity-show");
            } else {
              titleRef.value.classList.remove("anim-opacity-show");
              titleRef.value.classList.add("anim-opacity-hide");
              prevRef.value.classList.remove("anim-opacity-show");
              prevRef.value.classList.add("anim-opacity-hide");
              nextRef.value.classList.remove("anim-opacity-show");
              nextRef.value.classList.add("anim-opacity-hide");
            }
          }
        }
      });
    });

    const clickHandle = () => {
      emit("clickHandle", { name: "prevnext" });
    };

    return { sectionRef, section, titleRef, prevRef, nextRef, clickHandle };
  },
};
</script>

<style lang='scss'>
.prevnext {
  @include size(100%, auto);
  @include flex-layout(column, center, center);

  padding: 10vw 5vw;

  h5 {
    color: $color-font-primary;
    font-size: 2.4rem;
    text-align: center;
    padding: 1rem 0;
  }

  .prev {
    width: 100%;
    padding: 0.5rem;
    text-align: left;
    a {
      font-size: 1.4rem;
    }
  }

  .next {
    width: 100%;
    padding: 0.5rem;
    text-align: right;

    a {
      font-size: 1.4rem;
    }
  }
}

@media screen and (min-width: 414px) {
  .prevnext {
    padding: 10vw;
  }
}
@media screen and (min-width: 600px) {
  .prevnext {
    @include grid-layout(6, 2);
    h5 {
      @include grid-item(1, 7, 1, 2);
    }

    .prev {
      text-align: left;
      @include grid-item(1, 4, 2, 3);
    }

    .next {
      text-align: right;
      @include grid-item(4, 7, 2, 3);
    }
  }
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 962px) {
}
@media screen and (min-width: 1366px) {
  .prevnext {
    h5 {
      font-size: 3.4rem;
    }

    .prev {
      a {
        font-size: 2.4rem;
      }
    }

    .next {
      a {
        font-size: 2.4rem;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
}
</style>

