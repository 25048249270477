import * as THREE from 'three';

export default class Camera {
  constructor(debug, sizes, canvas, scene) {
    this.debug = debug;
    this.sizes = sizes;
    this.scene = scene;
    this.canvas = canvas;

    this.setCamera();
  }

  setCamera() {
    this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100);

    // start position
    this.instance.position.set(0, 7, 10);
    this.instance.rotation.set(-0.6, 0, 0);

    // debug
    if (this.debug.active) {
      // create a folder in dat gui
      this.debugFolder = this.debug.ui.addFolder('Camera');
      // add xyz controls
      this.debugFolder.add(this.instance.position, 'x').step(0.001).max(20).min(-20).name('pos-x');
      this.debugFolder.add(this.instance.position, 'y').step(0.001).max(20).min(-20).name('pos-y');
      this.debugFolder.add(this.instance.position, 'z').step(0.001).max(20).min(-20).name('pos-z');
      this.debugFolder.add(this.instance.rotation, 'x').step(0.0001).max(5).min(-5).name('rot-x');
    }



    // add to scene
    this.scene.add(this.instance)

    // group
    this.cameraGroup = new THREE.Group();

    //add the camera to cameraGroup
    this.cameraGroup.add(this.instance)

    // add to camera
    this.scene.add(this.cameraGroup);
  }


  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {

  }
}