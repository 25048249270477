import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import AffableChair from '../views/projects/AffableChair.vue'
import ConcrHeat from '../views/projects/ConcrHeat.vue'
import EverEWaste from '../views/projects/EverEWaste.vue'
import Moribund from '../views/projects/Moribund.vue'
import Psychoreceiver from '../views/projects/Psychoreceiver.vue'
import VRPlayground from '../views/projects/VRPlayground.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: About,
    props: false
  }
  ,
  {
    path: '/test',
    name: 'Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Test.vue'),
    props: false
  }
  ,
  {
    path: '/affablechair',
    name: 'AffableChair',
    component: AffableChair,
    props: true
  }
  ,
  {
    path: '/concrheat',
    name: 'ConcrHeat',
    component: ConcrHeat
    , props: true
  }
  ,
  {
    path: '/everewaste',
    name: 'EverEWaste',
    component: EverEWaste
    , props: true
  }
  ,
  {
    path: '/moribund',
    name: 'Moribund',
    component: Moribund
    , props: true
  }
  ,
  {
    path: '/psychoreceiver',
    name: 'Psychoreceiver',
    component: Psychoreceiver
    , props: true
  }
  ,
  {
    path: '/vrplayground',
    name: 'VRPlayground',
    component: VRPlayground
    , props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// reset page to the top
router.beforeEach(() => {
  window.scrollTo(0, 0);
})

export default router
