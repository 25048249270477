
import CameraController from "./CameraController";
export default class ModelController {
  constructor(time, sizes, camera, scroll, models) {
    this.models = models;
    this.sizes = sizes;
    this.time = time;
    this.camera = camera;
    this.scroll = scroll;

    this.enable = true;

    this.cameraOffsetY = this.camera.instance.position.y;
    this.modelsDistanceY = 6;
    this.modelOffsetX = 2;
    this.prevSection = 0;

    this.cameraController = new CameraController(this.camera, this.time, this.sizes, this.scroll, { modelsDistanceY: this.modelsDistanceY, cameraOffsetY: this.cameraOffsetY })

  }

  init() {

    // set model's position and scale
    this.setModelsPosition();
    this.setModelsScale();
    this.setModelsOffset()

    // tick listener
    this.time.on('tick', () => {
      if (this.enable) {
        this.update();
      }
    })

    // resize listener
    this.sizes.on('resize', () => {
      if (this.enable) {
        this.resize();
      }
    })

    // scrollY listener
    this.scroll.on('scrollY', () => {
      // show model
      this.show(this.scroll.section);

      // hide model
      if (this.prevSection < this.scroll.section) {
        // console.log('current section: ', this.scroll.section, "hiding section ", this.prevSection);
        this.hide(this.prevSection)
        this.prevSection = this.scroll.section;
      } else if (this.prevSection > this.scroll.section) {
        // console.log('current section: ', this.scroll.section, 'hiding section ', this.prevSection);
        this.hide(this.prevSection)
        this.prevSection = this.scroll.section;
      }
    })


  }

  setModelsPosition() {

    // set the models position.y // order is important otherwise I'd loop these.
    this.models.models.everewasteModel.position.y = this.modelsDistanceY * 1;
    this.models.models.concrheatModel.position.y = this.modelsDistanceY * 2;
    this.models.models.moribundModel.position.y = this.modelsDistanceY * 3;
    this.models.models.psychoreceiverModel.position.y = this.modelsDistanceY * 4;
    this.models.models.vrplaygroundModel.position.y = this.modelsDistanceY * 5;
    this.models.models.affablechairModel.position.y = this.modelsDistanceY * 6;

    // hide the models
    for (const key in this.models.materials) {
      this.models.materials[key].opacity = 0;
    }

    // show current section model at the start of the load or reload.
    // console.log('canvas is currently at section ', this.scroll.section)
    this.show(this.scroll.section);
  }

  setModelsScale() {
    // scale the models
    this.models.models.affablechairModel.scale.set(2, 2, 2);
    this.models.models.concrheatModel.scale.set(2, 2, 2);
    this.models.models.everewasteModel.scale.set(2, 2, 2);
    this.models.models.moribundModel.scale.set(2, 2, 2);
    this.models.models.psychoreceiverModel.scale.set(2, 2, 2);
    this.models.models.vrplaygroundModel.scale.set(2, 2, 2);
  }

  show(section) {
    switch (section) {
      case 0:
        // all models
        this.models.fadeIn('allmodels');
        break;
      case 1:
        // everewaste
        this.models.fadeIn('everewaste');
        break;
      case 2:
        // concrheat
        this.models.fadeIn('concrheat');
        break;
      case 3:
        // moribund
        this.models.fadeIn('moribund');
        break;
      case 4:
        // psycho
        this.models.fadeIn('psychoreceiver');
        break;
      case 5:
        // vrplayground
        this.models.fadeIn('vrplayground');
        break;
      case 6:
        // affable chair
        this.models.fadeIn('affablechair');
        break;
    }
  }

  hide(section) {
    switch (section) {
      case 0:
        // all models
        this.models.fadeOut('allmodels');
        break;
      case 1:
        // everewaste
        this.models.fadeOut('everewaste');
        break;
      case 2:
        // concrheat
        this.models.fadeOut('concrheat');
        break;
      case 3:
        // moribund
        this.models.fadeOut('moribund');
        break;
      case 4:
        // psycho
        this.models.fadeOut('psychoreceiver');
        break;
      case 5:
        // vrplayground
        this.models.fadeOut('vrplayground');
        break;
      case 6:
        // affable chair
        this.models.fadeOut('affablechair');
        break;
    }
  }

  setModelsOffset() {
    if (this.sizes.width < 1366) {
      this.models.models.allModels.position.x = 0;
    } else if (this.sizes.width >= 1366) {
      this.models.models.allModels.position.x = this.modelOffsetX * 1;
    }
  }

  resize() {
    this.setModelsOffset();
    this.models.resize();
  }

  update() {
    this.cameraController.update();
    this.models.update();
  }
}