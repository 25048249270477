<template>
  <section ref="sectionRef" id="challengeSection" class="challenge">
    <h2 ref="titleRef" class="underline anim-opacity-hide">challenge</h2>

    <div ref="imageRef" class="aspectRatio imgWrapper anim-opacity-hide">
      <img :src="require(`@/assets/${image}`)" alt="logo" />
    </div>

    <p ref="descRef" class="anim-opacity-hide">{{ description }}</p>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted, watchEffect } from "@vue/runtime-core";
export default {
  props: {
    description: String,
    image: String,
    scrollY: Number,
    sizes: Object,
  },
  setup(props) {
    const sectionRef = ref();
    const section = ref(-1);
    let oldSection = -1;

    const titleRef = ref();
    const descRef = ref();
    const imageRef = ref();

    onMounted(() => {
      // preset on mounted
      oldSection = section.value;
      // css property
      const elemCSS = window.getComputedStyle(sectionRef.value);

      watchEffect(() => {
        // prevent undefined value
        if (props.scrollY && props.sizes) {
          // section element properties
          const clientHeight = sectionRef.value.clientHeight;
          const offsetTop =
            sectionRef.value.offsetTop - parseInt(elemCSS.marginTop);
          const offsetBottom =
            sectionRef.value.offsetTop +
            clientHeight +
            parseInt(elemCSS.marginBottom);
          const sectionCenter = (offsetBottom - offsetTop) / 2;

          // test section's center is within bounds
          if (
            offsetTop + sectionCenter > props.scrollY &&
            offsetTop + sectionCenter < props.scrollY + props.sizes.height
          ) {
            section.value = 0;
          } else {
            // console.log("header is NOT in view");
            section.value = -1;
          }

          if (oldSection != section.value) {
            // assign new old section
            oldSection = section.value;

            if (section.value === 0) {
              // console.log("goal is in view");
              titleRef.value.classList.remove("anim-opacity-hide");
              titleRef.value.classList.add("anim-opacity-show");
              descRef.value.classList.remove("anim-opacity-hide");
              descRef.value.classList.add("anim-opacity-show");
              imageRef.value.classList.remove("anim-opacity-hide");
              imageRef.value.classList.add("anim-opacity-show");
            } else {
              // console.log("goal is NOT in view");
              titleRef.value.classList.remove("anim-opacity-show");
              titleRef.value.classList.add("anim-opacity-hide");
              descRef.value.classList.remove("anim-opacity-show");
              descRef.value.classList.add("anim-opacity-hide");
              imageRef.value.classList.remove("anim-opacity-show");
              imageRef.value.classList.add("anim-opacity-hide");
            }
          }
        }
      });
    });
    return { sectionRef, section, titleRef, descRef, imageRef };
  },
};
</script>

<style lang='scss'>
.challenge {
  @include size(100%, auto);
  @include flex-layout(column, center, center);
  padding: 10vw 5vw;

  h2 {
    color: $color-font-primary;
    font-size: 2.5rem;
    margin-bottom: 1.4rem;
  }

  .imgWrapper {
    margin: 0;
  }

  p {
    color: $color-font-primary;
    font-size: 1rem;
    padding: 0;
    margin-top: 1.4rem;
    line-height: 1.5;
  }
}

@media screen and (min-width: 414px) {
  .challenge {
    padding: 10vw;
  }
}
@media screen and (min-width: 768px) {
  .challenge {
    @include grid-layout(9, 4);
    padding: 5vw 10vw;

    h2 {
      @include grid-item(1, 6, 1, 2);
      @include grid-self(end, start);
      padding: 0;
      margin: 0.5rem 2rem;
    }

    .imgWrapper {
      @include grid-item(6, 10, 2, 5);
      @include grid-self(start, center);
      margin: 0.5rem 0;
    }

    p {
      @include grid-item(1, 6, 2, 5);
      @include grid-self(start, start);
      padding: 0;
      margin: 0.5rem 3rem 0 0;
    }
  }
}
@media screen and (min-width: 962px) {
}
@media screen and (min-width: 1366px) {
  .challenge {
    h2 {
      font-size: 4rem;
      margin: 0.5rem 2.8rem;
    }
    p {
      font-size: 1.4rem;
    }
  }
}
@media screen and (min-width: 1920px) {
}
</style>

