<template>
  <canvas ref="headerCanvasEl" class="headerCanvas"> </canvas>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted, onUnmounted, watchEffect } from "@vue/runtime-core";
import HeaderWorld from "@/webgl/headerCanvas/HeaderWorld.js";
import HeaderModelController from "@/webgl/headerCanvas/HeaderModelController.js";
export default {
  props: {
    modelName: String,
    resources: Object,
    percent: Number,
  },
  components: {},
  setup(props) {
    const headerCanvasEl = ref();

    // create world
    const world = new HeaderWorld();
    let modelController = null;
    let isResourcesLoaded = false;

    onMounted(() => {
      // set canvas
      world.canvas = headerCanvasEl.value;
      world.init();

      watchEffect(() => {
        if (props.percent === 100 && !isResourcesLoaded) {
          // set resources
          world.resources = props.resources;

          // init world
          world.init();

          modelController = new HeaderModelController(
            world.time,
            world.sizes,
            world.camera,
            world.scene,
            world.models,
            props.modelName
          );

          modelController.init();

          // set  state
          isResourcesLoaded = true;
        }
      });
    });

    onUnmounted(() => {
      world.dispose();
      modelController.enable = false;
    });

    return {
      headerCanvasEl,
    };
  },
};
</script>

<style lang='scss'>
.headerCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
  outline: none;
  z-index: -1;
}
</style>