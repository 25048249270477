import Mouse from '../utils/Mouse'

export default class HomeCameraController {
  constructor(camera, time, sizes, options) {
    this.camera = camera;
    this.time = time;
    this.sizes = sizes;
    this.mouse = new Mouse();
    this.options = options;

    this.centerXY = {
      x: 0,
      y: 0
    }

    // event listener
    this.mouse.on('mousemove', () => {
      // start from the center of screen
      this.centerXY.x = this.mouse.x / this.sizes.width - .5;
      this.centerXY.y = this.mouse.y / this.sizes.height - .5;
    })
  }


  animateParallax() {
    const parallaxX = this.centerXY.x * 0.5;
    const parallaxY = - this.centerXY.y * 0.5;

    this.camera.cameraGroup.position.x += (parallaxX - this.camera.cameraGroup.position.x) * 5 * this.time.delta * 0.001;
    this.camera.cameraGroup.position.y += (parallaxY - this.camera.cameraGroup.position.y) * 5 * this.time.delta * 0.001;

  }

  resize() {

  }

  update() {
    this.animateParallax();
  }
}