<template>
  <nav class="nav">
    <span>
      <router-link class="navHome" :to="{ path: '/' }"> projects </router-link>
    </span>
    <span>
      <router-link class="navAbout" to="About"> about </router-link>
    </span>
  </nav>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang='scss'>
.nav {
  position: fixed;
  top: 0.4rem;
  right: 1.4rem;
  z-index: 100;

  span {
    display: inline-block;
    margin: 1rem 0.8rem;
    font-size: 1rem;
    letter-spacing: 1px;
  }

  a {
    color: $color-font-primary;
  }
  a.router-link-exact-active {
    color: $color-highlight-primary;
  }
}

@media screen and (min-width: 414px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 962px) {
  .nav {
    span {
      font-size: 1.4rem;
    }
  }
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1920px) {
}
</style>