<template>
  <div ref="sectionRef" class="progressLoader">
    <svg ref="svgRef" id="svg" viewBox="0 0 153 112">
      <defs>
        <!-- Percentage gradient -->
        <linearGradient id="grad-percent" gradientTransform="rotate(90)">
          <stop offset="0" stop-color="white" stop-opacity="1" />
          <stop
            ref="stop2PercentRef"
            offset="0.3"
            stop-color="white"
            stop-opacity="1"
          />
          <stop
            ref="stop1PercentRef"
            offset="0.3"
            stop-color="white"
            stop-opacity="0"
          />
        </linearGradient>
        <mask id="mask-outline">
          <path
            d="M108.74,.5h-15.12l-19.92,54.96H38.9v14.4h29.52l-7.92,21.84s-2.28,4.56-17.04,4.56-28.32-15.72-28.32-41.28S26.3,13.98,43.94,13.98c6.21,0,10.45,1.57,14.64,3.32,3.47,1.45,6.58,4.46,6.58,4.46,0,0,4.63-10.68,5.11-12-2.11-2.89-7.54-5.29-10.41-6.52-3.39-1.45-7.17-2.74-16.16-2.74C19.1,.5,.5,23.78,.5,54.98s16.2,55.92,43.2,55.92,28.8-9.36,28.8-9.36l11.76-31.68h34.56l14.88,40.8h17.76L108.74,.5Zm-19.44,54.96l12-32.4,12.24,32.4h-24.24Z"
            fil="none"
            stroke="white"
            stroke-miterlimit="10"
            stroke-width="2px"
            transform="scale(0.95 0.95) translate(4 3)"
          />
        </mask>
        <mask id="mask-plain">
          <path
            d="M108.74,.5h-15.12l-19.92,54.96H38.9v14.4h29.52l-7.92,21.84s-2.28,4.56-17.04,4.56-28.32-15.72-28.32-41.28S26.3,13.98,43.94,13.98c6.21,0,10.45,1.57,14.64,3.32,3.47,1.45,6.58,4.46,6.58,4.46,0,0,4.63-10.68,5.11-12-2.11-2.89-7.54-5.29-10.41-6.52-3.39-1.45-7.17-2.74-16.16-2.74C19.1,.5,.5,23.78,.5,54.98s16.2,55.92,43.2,55.92,28.8-9.36,28.8-9.36l11.76-31.68h34.56l14.88,40.8h17.76L108.74,.5Zm-19.44,54.96l12-32.4,12.24,32.4h-24.24Z"
            fill="white"
            transform="scale(0.95 0.95) translate(4 3)"
          />
        </mask>
        <mask id="mask-percent-negative">
          <rect
            x="0"
            y="0"
            width="153"
            height="112"
            mask="url(#mask-plain)"
            fill="url(#grad-percent)"
          />
        </mask>
      </defs>
      <!-- background -->
      <rect
        class="rectBG-a"
        x="0"
        y="0"
        width="153"
        height="112"
        mask="url(#mask-plain)"
      />
      <!-- masking negative -->
      <rect
        class="rectBG-negative"
        x="0"
        y="0"
        width="153"
        height="112"
        mask="url(#mask-percent-negative)"
      />
      <!-- outline -->
      <rect
        class="rectBG-a"
        x="0"
        y="0"
        width="153"
        height="112"
        mask="url(#mask-outline)"
      />
    </svg>
  </div>
</template>

<script>
import gsap from "gsap";
import { onMounted, ref, watchEffect } from "vue";
export default {
  props: { percent: Number },
  setup(props) {
    const sectionRef = ref();
    const svgRef = ref();
    const stop1PercentRef = ref();
    const stop2PercentRef = ref();

    // let prevPercent = 1;
    let nextPercent = props.percent / 100;

    onMounted(() => {
      watchEffect(() => {
        // calculate new nextPercent
        nextPercent = props.percent / 100;

        // Finish loading
        if (props.percent === 100) {
          // animate progress
          gsap.to(
            stop1PercentRef.value,

            {
              attr: { offset: 1 - nextPercent },
              duration: 0.25,
            }
          );
          gsap.to(
            stop2PercentRef.value,

            {
              attr: { offset: 1 - nextPercent },
              duration: 0.25,
            }
          );

          gsap.to(svgRef.value, {
            opacity: 0,
            delay: 0.5,
            duration: 1,
          });

          // animate leave
          // ANIMATIONG THE SECTION
          sectionRef.value.style.transformOrigin = "bottom";
          gsap.to(sectionRef.value, {
            delay: 1.5,
            duration: 1.5,
            y: "100%",
            onComplete: () => {
              // hide it well in case the resizing bug
              sectionRef.value.style.transform = "scale(0)";
            },
          });
        } else {
          // animate progress
          gsap.to(
            stop1PercentRef.value,

            {
              attr: { offset: 1 - nextPercent },
              duration: 0.25,
            }
          );
          gsap.to(
            stop2PercentRef.value,

            {
              attr: { offset: 1 - nextPercent },
              duration: 0.25,
            }
          );
        }

        // prevPercent = 1 - nextPercent;
      });
    });

    return {
      sectionRef,
      svgRef,
      stop1PercentRef,
      stop2PercentRef,
    };
  },
};
</script>

<style lang='scss'>
.progressLoader {
  @include size(100%, 100vh);
  @include flex-layout(column, center, center);
  position: fixed;
  top: 0;
  left: 0;
  font-size: 6vw;
  z-index: 1000;
  background: $color-bg-primary;

  #svg {
    width: max(10vh, 10vw);
    .rectBG-a {
      fill: $color-highlight-primary;
      fill-rule: evenodd;
    }

    .rectBG-b {
      fill: $color-highlight-secondary;
      fill-rule: evenodd;
    }

    .rectBG-negative {
      fill: $color-bg-primary;
    }
  }
}
</style>