
import HeaderCameraController from './HeaderCameraController'
export default class HeaderModelController {
  constructor(time, sizes, camera, scene, models, modelName) {
    this.time = time;
    this.modelName = modelName;
    this.sizes = sizes;
    this.camera = camera;
    this.scene = scene;
    this.models = models;

    this.enable = true;

    this.cameraOffsetY = this.camera.instance.position.y;

    this.cameraController = new HeaderCameraController(this.camera, this.time, this.sizes, { modelsDistanceY: 0, cameraOffsetY: this.cameraOffsetY })
  }

  init() {
    this.setModelPosition()
    this.setModelScale();

    // tick listener
    this.time.on('tick', () => {
      if (this.enable) {
        this.update();
      }

    })

    // resize listener
    this.sizes.on('resize', () => {
      if (this.enable) {
        this.resize();
      }
    })
  }

  setModelPosition() {
    for (const key in this.models.models) {

      if (key === this.modelName) {
        this.models.models[key].position.set(0, 0, 0);
      } else {
        // hide it far
        this.models.models[key].position.set(0, 0, 999);
        this.models.models[key].visible = false;
      }
    }
  }

  setModelScale() {
    for (const key in this.models.models) {

      if (key === this.modelName) {
        this.models.models[key].scale.set(2, 2, 2);
      }
    }
  }


  resize() {
    this.models.resize();
  }

  update() {
    this.cameraController.update();
    this.models.update();
  }

}