<template>
  <div class="home">
    <Canvas :resources="resources" :percent="percent" :section="section" />
    <SectionBullets :section="section" />
    <Hero
      viewLink="About"
      :sectionID="0"
      :section="section"
      addedID="heroHome"
    />
    <ProjectItem
      viewLink="EverEWaste"
      name="Everewaste"
      type="Web Experience"
      :sectionID="1"
      :section="section"
      :width="width"
      addedID="everewasteHome"
    />
    <ProjectItem
      viewLink="ConcrHeat"
      name="ConcrHeat"
      type="Art Installation"
      :sectionID="2"
      :section="section"
      :width="width"
      addedID="concrheatHome"
    />
    <ProjectItem
      viewLink="Moribund"
      name="Moribund"
      type="VR Experience"
      :sectionID="3"
      :section="section"
      :width="width"
      addedID="moribundHome"
    />
    <ProjectItem
      viewLink="Psychoreceiver"
      name="Psychoreceiver"
      type="Interactive Short Movie"
      :sectionID="4"
      :section="section"
      :width="width"
      addedID="psychoreceiverHome"
    />
    <ProjectItem
      viewLink="VRPlayground"
      name="VR Playground"
      type="VR Development"
      :sectionID="5"
      :section="section"
      :width="width"
      addedID="vrplaygroundHome"
    />
    <ProjectItem
      viewLink="AffableChair"
      name="Affable Chair"
      type="Therapeutic Chair Concept"
      :sectionID="6"
      :section="section"
      :width="width"
      addedID="affablechairHome"
    />

    <Footer
      viewLink="About"
      :sectionID="7"
      :section="section"
      addedID="footerHome"
    />
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  </div>
</template>

<script>
// import vue modules
import { onUnmounted, ref } from "@vue/runtime-core";

// import components
import SectionBullets from "@/components/utilities/SectionBullets.vue";
import Canvas from "@/components/homeView/Canvas.vue";
import Hero from "@/components/homeView/Hero.vue";
import ProjectItem from "@/components/homeView/ProjectItem.vue";
import Footer from "@/components/homeView/Footer.vue";
import ScrollY from "@/webgl/utils/ScrollY.js";

export default {
  name: "Home",
  props: {
    // resources from App.vue
    resources: Object,
    percent: Number,
  },
  components: {
    Canvas,
    SectionBullets,
    Hero,
    ProjectItem,
    Footer,
  },
  setup() {
    const scroll = new ScrollY();
    const section = ref(0);
    const width = ref(0);

    // Listener
    scroll.on("scrollY", () => {
      section.value = scroll.section;
    });

    scroll.on("scrollResize", () => {
      width.value = scroll.sizes.width;
    });

    onUnmounted(() => {
      scroll.enable = false;
    });

    // return
    return { section, width };
  },
};
</script>

<style lang="scss">
</style>
