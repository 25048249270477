<template>
  <div ref="sectionBulletsRef" class="sectionBullets" @click="clickHandle">
    <div
      v-for="section in sections"
      :key="section.name"
      :class="[section.class]"
    >
      <a :href="section.name"></a>
    </div>
  </div>
</template>

<script>
import { onMounted, watchEffect, ref } from "@vue/runtime-core";

export default {
  props: {
    section: Number,
  },

  setup(props) {
    const sections = [
      {
        name: "#heroHome",
        class: "bullet heroSection cursor",
      },
      {
        name: "#everewasteHome",
        class: "bullet everewasteSection cursor",
      },
      {
        name: "#concrheatHome",
        class: "bullet concrheatSection cursor",
      },
      {
        name: "#moribundHome",
        class: "bullet moribundSection cursor",
      },
      {
        name: "#psychoreceiverHome",
        class: "bullet psychoreceiverSection cursor",
      },
      {
        name: "#vrplaygroundHome",
        class: "bullet vrplaygroundSection cursor",
      },
      {
        name: "#affablechairHome",
        class: "bullet affablechairSection cursor",
      },
      {
        name: "#footerHome",
        class: "bullet footerSection cursor",
      },
    ];

    let sectionElements = null;

    const sectionBulletsRef = ref();

    onMounted(() => {
      sectionElements = [...document.querySelectorAll(".bullet")];

      watchEffect(() => {
        // test if section is 0
        if (props.section === 0) {
          sectionBulletsRef.value.classList.add("hide");
        } else {
          sectionBulletsRef.value.classList.remove("hide");
        }

        // test if it exist
        if (sectionElements) {
          // remove all 'currentBullet' class
          sectionElements.forEach((elem) =>
            elem.classList.remove("currentBullet")
          );

          // add/toggle class
          sectionElements[props.section].classList.toggle("currentBullet");
        }
      });
    });

    return {
      sections,
      // clickHandle,
      sectionBulletsRef,
    };
  },
};
</script>

<style lang="scss">
.sectionBullets {
  display: none;
  z-index: 300;
  position: fixed;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);

  opacity: 1;
  transition: 1s opacity ease-in-out;

  &.hide {
    opacity: 0;
  }

  .bullet {
    background-color: $color-font-primary;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    margin: 0.7rem 0;
    transform: scale(0.8);

    transition: all 1s ease-in-out;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .currentBullet {
    background-color: $color-highlight-primary;
    transform: scale(1);
  }
}

@media screen and (min-width: 1366px) {
  .sectionBullets {
    display: inline-block;
  }
}
</style>