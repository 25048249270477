<template>
  <section ref="sectionRef" id="goalSection" class="goal">
    <h2 ref="titleRef" class="underline anim-opacity-hide">goal</h2>
    <p ref="descRef" class="anim-opacity-hide">
      {{ description }} <br /><br />
      <a v-show="showWebsiteURL" :href="websiteURL" target="_blank">
        Link to {{ title }}</a
      >
    </p>
  </section>
</template>

<script>
import { onMounted, ref, watchEffect, computed } from "@vue/runtime-core";
export default {
  props: {
    title: String,
    description: String,
    websiteURL: String,
    scrollY: Number,
    sizes: Object,
  },
  setup(props) {
    const sectionRef = ref();
    const section = ref(-1);
    let oldSection = -1;

    const titleRef = ref();
    const descRef = ref();

    const showWebsiteURL = computed(() => {
      return props.websiteURL != "";
    });

    onMounted(() => {
      // preset on mounted
      oldSection = section.value;

      // css property
      const elemCSS = window.getComputedStyle(sectionRef.value);

      watchEffect(() => {
        // prevent undefined value
        if (props.scrollY && props.sizes) {
          // section element properties
          const clientHeight = sectionRef.value.clientHeight;
          const offsetTop =
            sectionRef.value.offsetTop - parseInt(elemCSS.marginTop);
          const offsetBottom =
            sectionRef.value.offsetTop +
            clientHeight +
            parseInt(elemCSS.marginBottom);
          const sectionCenter = (offsetBottom - offsetTop) / 2;

          // test section's center is within bounds
          if (
            offsetTop + sectionCenter > props.scrollY &&
            offsetTop + sectionCenter < props.scrollY + props.sizes.height
          ) {
            section.value = 0;
          } else {
            // console.log("header is NOT in view");
            section.value = -1;
          }

          if (oldSection != section.value) {
            // assign new old section
            oldSection = section.value;

            if (section.value === 0) {
              // console.log("goal is in view");
              titleRef.value.classList.remove("anim-opacity-hide");
              titleRef.value.classList.add("anim-opacity-show");
              descRef.value.classList.remove("anim-opacity-hide");
              descRef.value.classList.add("anim-opacity-show");
            } else {
              // console.log("goal is NOT in view");
              titleRef.value.classList.remove("anim-opacity-show");
              titleRef.value.classList.add("anim-opacity-hide");
              descRef.value.classList.remove("anim-opacity-show");
              descRef.value.classList.add("anim-opacity-hide");
            }
          }
        }
      });
    });
    return { sectionRef, section, titleRef, descRef, showWebsiteURL };
  },
};
</script>

<style lang='scss'>
.goal {
  @include size(100%, auto);
  @include flex-layout(column, center, center);
  padding: 15vw 5vw 10vw 5vw;

  h2 {
    color: $color-font-primary;
    font-size: 2.5rem;
  }

  p {
    color: $color-font-primary;
    font-size: 1rem;
    margin-top: 1.4rem;
    padding: 0;
    line-height: 1.5;
  }
}

@media screen and (min-width: 414px) {
  .goal {
    padding: 15vw 10vw 10vw 10vw;
  }
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 962px) {
}
@media screen and (min-width: 1366px) {
  .goal {
    h2 {
      font-size: 4rem;
    }
    p {
      width: 70%;
      font-size: 1.4rem;
    }
  }
}
@media screen and (min-width: 1920px) {
}
</style>