import * as THREE from 'three'
import gsap from 'gsap'

export default class Models {
  constructor(scene, resources, time) {
    this.scene = scene;
    this.resources = resources;
    this.time = time;
    this.opacity = 0.3;

    this.rotateSpeed = 0.00005;
    this.rotationScale = 1;

    if (resources) {
      this.setModels();


      //reset
      for (const key in this.models) {
        this.models[key].visible = true;
        this.models[key].position.set(0, 0, 0);
        this.models[key].scale.set(1, 1, 1);
      }
    }
  }

  setModels() {
    this.models = {}
    this.models.affablechairModel = this.resources.items.affablechairModel.scene;
    this.models.concrheatModel = this.resources.items.concrheatModel.scene;
    this.models.everewasteModel = this.resources.items.everewasteModel.scene;
    this.models.moribundModel = this.resources.items.moribundModel.scene;
    this.models.psychoreceiverModel = this.resources.items.psychoreceiverModel.scene;
    this.models.vrplaygroundModel = this.resources.items.vrplaygroundModel.scene;
    this.models.allModels = this.resources.items.model.scene;

    // change its name
    this.models.allModels.name = 'allModels';


    // add to scene
    this.scene.add(this.models.affablechairModel);
    this.scene.add(this.models.concrheatModel)
    this.scene.add(this.models.everewasteModel)
    this.scene.add(this.models.moribundModel);
    this.scene.add(this.models.psychoreceiverModel)
    this.scene.add(this.models.vrplaygroundModel);
    this.scene.add(this.models.allModels);

    // texture
    this.textures = {}
    this.textures.affablechairTexture = this.getTexture('affablechairTexture')
    this.textures.concrheatTexture = this.getTexture('concrheatTexture')
    this.textures.everewasteTexture = this.getTexture('everewasteTexture')
    this.textures.moribundTexture = this.getTexture('moribundTexture')
    this.textures.psychoreceiverTexture = this.getTexture('psychoreceiverTexture')
    this.textures.vrplaygroundTexture = this.getTexture('vrplaygroundTexture')

    // meshes
    this.meshes = {}
    this.meshes.affablechairMesh = this.models.affablechairModel.children.find(child => child.name === 'affablechair');
    this.meshes.concrheatMesh = this.models.concrheatModel.children.find(child => child.name === 'concrheat');
    this.meshes.everewasteMesh = this.models.everewasteModel.children.find(child => child.name === 'everewaste');
    this.meshes.moribundMesh = this.models.moribundModel.children.find(child => child.name === 'moribund');
    this.meshes.psychoreceiverMesh = this.models.psychoreceiverModel.children.find(child => child.name === 'psychoreceiver');
    this.meshes.vrplaygroundMesh = this.models.vrplaygroundModel.children.find(child => child.name === 'vrplayground');
    this.meshes.vrplaygroundArrowMesh = this.models.vrplaygroundModel.children.find(child => child.name === 'vrplayground-arrow');
    this.meshes.vrplaygroundGateMesh = this.models.vrplaygroundModel.children.find(child => child.name === 'vrplayground-gate');
    this.meshes.vrplaygroundDomeMesh = this.models.vrplaygroundModel.children.find(child => child.name === 'vrplayground-dome');
    this.meshes.vrplaygroundPortalMesh = this.models.vrplaygroundModel.children.find(child => child.name === 'vrplayground-portal');
    this.meshes.vrplaygroundPanelMesh = this.models.vrplaygroundModel.children.find(child => child.name === 'vrplayground-panel');
    // grouped models
    this.meshes.affablechairGroupedMesh = this.models.allModels.children.find(child => child.name === 'affablechair');
    this.meshes.concrheatGroupedMesh = this.models.allModels.children.find(child => child.name === 'concrheat');
    this.meshes.everewasteGroupedMesh = this.models.allModels.children.find(child => child.name === 'everewaste');
    this.meshes.moribundGroupedMesh = this.models.allModels.children.find(child => child.name === 'moribund');
    this.meshes.psychoreceiverGroupedMesh = this.models.allModels.children.find(child => child.name === 'psychoreceiver');
    this.meshes.vrplaygroundGroupedMesh = this.models.allModels.children.find(child => child.name === 'vrplaygroundModel');
    this.meshes.vrplaygroundArrowGroupedMesh = this.models.allModels.children.find(child => child.name === 'vrplaygroundArrow');
    this.meshes.vrplaygroundGateGroupedMesh = this.models.allModels.children.find(child => child.name === 'vrplaygroundGate');
    this.meshes.vrplaygroundDomeGroupedMesh = this.models.allModels.children.find(child => child.name === 'vrplaygroundDome');
    this.meshes.vrplaygroundPortalGroupedMesh = this.models.allModels.children.find(child => child.name === 'vrplaygroundPortal');
    this.meshes.vrplaygroundPanelGroupedMesh = this.models.allModels.children.find(child => child.name === 'vrplaygroundPanel');

    // materials
    this.materials = {}
    this.materials.affablechairMaterial = new THREE.MeshBasicMaterial({ map: this.textures.affablechairTexture, transparent: true });
    this.materials.concrheatMaterial = new THREE.MeshBasicMaterial({ map: this.textures.concrheatTexture, transparent: true });
    this.materials.everewasteMaterial = new THREE.MeshBasicMaterial({ map: this.textures.everewasteTexture, transparent: true });
    this.materials.moribundMaterial = new THREE.MeshBasicMaterial({ map: this.textures.moribundTexture, transparent: true });
    this.materials.psychoreceiverMaterial = new THREE.MeshBasicMaterial({ map: this.textures.psychoreceiverTexture, transparent: true });
    this.materials.vrplaygroundMaterial = new THREE.MeshBasicMaterial({ map: this.textures.vrplaygroundTexture, transparent: true });
    this.materials.vrplaygroundArrowMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x12E7E7), transparent: true, opacity: this.opacity });
    this.materials.vrplaygroundPortalMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x2E3FE7), transparent: true, opacity: this.opacity });
    this.materials.vrplaygroundPanelMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0xe7e7e7), transparent: true, opacity: this.opacity });
    this.materials.vrplaygroundGateMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x00E700), transparent: true, opacity: this.opacity });
    this.materials.vrplaygroundDomeMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x2E3FE7), transparent: true, opacity: this.opacity });
    // group models
    this.materials.affablechairGroupedMaterial = new THREE.MeshBasicMaterial({ map: this.textures.affablechairTexture, transparent: true });
    this.materials.concrheatGroupedMaterial = new THREE.MeshBasicMaterial({ map: this.textures.concrheatTexture, transparent: true });
    this.materials.everewasteGroupedMaterial = new THREE.MeshBasicMaterial({ map: this.textures.everewasteTexture, transparent: true });
    this.materials.moribundGroupedMaterial = new THREE.MeshBasicMaterial({ map: this.textures.moribundTexture, transparent: true });
    this.materials.psychoreceiverGroupedMaterial = new THREE.MeshBasicMaterial({ map: this.textures.psychoreceiverTexture, transparent: true });
    this.materials.vrplaygroundGroupedMaterial = new THREE.MeshBasicMaterial({ map: this.textures.vrplaygroundTexture, transparent: true });
    this.materials.vrplaygroundArrowGroupedMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x12E7E7), transparent: true, opacity: this.opacity });
    this.materials.vrplaygroundPortalGroupedMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x2E3FE7), transparent: true, opacity: this.opacity });
    this.materials.vrplaygroundPanelGroupedMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0xe7e7e7), transparent: true, opacity: this.opacity });
    this.materials.vrplaygroundGateGroupedMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x00E700), transparent: true, opacity: this.opacity });
    this.materials.vrplaygroundDomeGroupedMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x2E3FE7), transparent: true, opacity: this.opacity });

    // set the materials
    this.meshes.affablechairMesh.material = this.materials.affablechairMaterial;
    this.meshes.concrheatMesh.material = this.materials.concrheatMaterial;
    this.meshes.everewasteMesh.material = this.materials.everewasteMaterial;
    this.meshes.moribundMesh.material = this.materials.moribundMaterial;
    this.meshes.psychoreceiverMesh.material = this.materials.psychoreceiverMaterial;
    this.meshes.vrplaygroundMesh.material = this.materials.vrplaygroundMaterial;
    this.meshes.vrplaygroundArrowMesh.material = this.materials.vrplaygroundArrowMaterial;
    this.meshes.vrplaygroundPortalMesh.material = this.materials.vrplaygroundPortalMaterial;
    this.meshes.vrplaygroundPanelMesh.material = this.materials.vrplaygroundPanelMaterial;
    this.meshes.vrplaygroundGateMesh.material = this.materials.vrplaygroundGateMaterial;
    this.meshes.vrplaygroundDomeMesh.material = this.materials.vrplaygroundDomeMaterial;
    // grouped maodels
    this.meshes.affablechairGroupedMesh.material = this.materials.affablechairGroupedMaterial;
    this.meshes.concrheatGroupedMesh.material = this.materials.concrheatGroupedMaterial;
    this.meshes.everewasteGroupedMesh.material = this.materials.everewasteGroupedMaterial;
    this.meshes.moribundGroupedMesh.material = this.materials.moribundGroupedMaterial;
    this.meshes.psychoreceiverGroupedMesh.material = this.materials.psychoreceiverGroupedMaterial;
    this.meshes.vrplaygroundGroupedMesh.material = this.materials.vrplaygroundGroupedMaterial;
    this.meshes.vrplaygroundArrowGroupedMesh.material = this.materials.vrplaygroundArrowGroupedMaterial;
    this.meshes.vrplaygroundGateGroupedMesh.material = this.materials.vrplaygroundGateGroupedMaterial;
    this.meshes.vrplaygroundDomeGroupedMesh.material = this.materials.vrplaygroundDomeGroupedMaterial;
    this.meshes.vrplaygroundPortalGroupedMesh.material = this.materials.vrplaygroundPortalGroupedMaterial;
    this.meshes.vrplaygroundPanelGroupedMesh.material = this.materials.vrplaygroundPanelGroupedMaterial;

  }

  // get the texture from the resources
  getTexture(name) {

    // get the texture in the resources 'items' array
    const texture = this.resources.items[name];
    // flag false flipY
    texture.flipY = false;
    // set encoding
    texture.encoding = THREE.sRGBEncoding;

    return texture;
  }

  // animation
  fadeIn(modelName) {
    switch (modelName) {
      case 'affablechair':
        this.animate('affablechairMaterial', 1);
        this.rotate('affablechairModel', 1);
        break;
      case 'concrheat':
        this.animate('concrheatMaterial', 1);
        this.rotate('concrheatModel', 1);
        break;
      case 'everewaste':
        this.animate('everewasteMaterial', 1, -1);
        this.rotate('everewasteModel', -1);
        break;
      case 'moribund':
        this.animate('moribundMaterial', 1,);
        this.rotate('moribundModel', -1);
        break;
      case 'psychoreceiver':
        this.animate('psychoreceiverMaterial', 1);
        this.rotate('psychoreceiverModel', 1);
        break;
      case 'vrplayground':
        this.animate('vrplaygroundMaterial', 1,);
        this.animate('vrplaygroundArrowMaterial', this.opacity);
        this.animate('vrplaygroundPortalMaterial', this.opacity);
        this.animate('vrplaygroundPanelMaterial', this.opacity);
        this.animate('vrplaygroundGateMaterial', this.opacity);
        this.animate('vrplaygroundDomeMaterial', this.opacity);
        this.rotate('vrplaygroundModel', -1);
        break;
      case 'allmodels':
        this.animate('affablechairGroupedMaterial', 1);
        this.animate('concrheatGroupedMaterial', 1);
        this.animate('everewasteGroupedMaterial', 1);
        this.animate('moribundGroupedMaterial', 1);
        this.animate('psychoreceiverGroupedMaterial', 1);
        this.animate('vrplaygroundGroupedMaterial', 1);
        this.animate('vrplaygroundArrowGroupedMaterial', this.opacity);
        this.animate('vrplaygroundPortalGroupedMaterial', this.opacity);
        this.animate('vrplaygroundPanelGroupedMaterial', this.opacity);
        this.animate('vrplaygroundGateGroupedMaterial', this.opacity);
        this.animate('vrplaygroundDomeGroupedMaterial', this.opacity);
        this.rotate('allModels', 1);
        break;
    }
  }

  fadeOut(modelName) {
    switch (modelName) {
      case 'affablechair':
        this.animate('affablechairMaterial', 0);
        this.rotate('affablechairModel', 1);
        break;
      case 'concrheat':
        this.animate('concrheatMaterial', 0);
        this.rotate('concrheatModel', 1);
        break;
      case 'everewaste':
        this.animate('everewasteMaterial', 0, -1);
        this.rotate('everewasteModel', -1);
        break;
      case 'moribund':
        this.animate('moribundMaterial', 0,);
        this.rotate('moribundModel', -1);
        break;
      case 'psychoreceiver':
        this.animate('psychoreceiverMaterial', 0);
        this.rotate('psychoreceiverModel', 1);
        break;
      case 'vrplayground':
        this.animate('vrplaygroundMaterial', 0)
        this.animate('vrplaygroundArrowMaterial', 0)
        this.animate('vrplaygroundPortalMaterial', 0)
        this.animate('vrplaygroundPanelMaterial', 0)
        this.animate('vrplaygroundGateMaterial', 0)
        this.animate('vrplaygroundDomeMaterial', 0)
        this.rotate('vrplaygroundModel', -1);
        break;
      case 'allmodels':
        this.animate('affablechairGroupedMaterial', 0);
        this.animate('concrheatGroupedMaterial', 0);
        this.animate('everewasteGroupedMaterial', 0);
        this.animate('moribundGroupedMaterial', 0);
        this.animate('psychoreceiverGroupedMaterial', 0);
        this.animate('vrplaygroundGroupedMaterial', 0);
        this.animate('vrplaygroundArrowGroupedMaterial', 0);
        this.animate('vrplaygroundPortalGroupedMaterial', 0);
        this.animate('vrplaygroundPanelGroupedMaterial', 0);
        this.animate('vrplaygroundGateGroupedMaterial', 0);
        this.animate('vrplaygroundDomeGroupedMaterial', 0);
        this.rotate('allModels', 1);
        break;
    }
  }

  dispose() {
    for (const key in this.materials) {
      this.materials[key].dispose();
    }

    for (const key in this.textures) {
      this.textures[key].dispose();
    }

    // hide for the vue navigation transition
    for (const key in this.models) {
      this.models[key].visible = false;
    }

    // console.log('DISPOSING MODELS')
  }

  animate(name, toOpacity) {
    gsap.to(this.materials[name], {
      opacity: toOpacity,
      duration: 1.5,
      // ease: "power3.out"
      ease: "expo.out"
    })
  }

  rotate(name, toRotation) {
    gsap.to(this.models[name].rotation, {
      y: `+=${toRotation}`,
      ease: 'expo.out',
      duration: 1.5,
    })
  }


  rotateAll() {

    this.models.allModels.rotation.y += this.time.delta * this.rotateSpeed * this.rotationScale;
    this.models.everewasteModel.rotation.y -= this.time.delta * this.rotateSpeed * this.rotationScale;
    this.models.concrheatModel.rotation.y += this.time.delta * this.rotateSpeed * this.rotationScale;
    this.models.moribundModel.rotation.y -= this.time.delta * this.rotateSpeed * this.rotationScale;
    this.models.psychoreceiverModel.rotation.y += this.time.delta * this.rotateSpeed * this.rotationScale;
    this.models.vrplaygroundModel.rotation.y -= this.time.delta * this.rotateSpeed * this.rotationScale;
    this.models.affablechairModel.rotation.y += this.time.delta * this.rotateSpeed * this.rotationScale;
  }

  resize() {

  }

  update() {
    this.rotateAll();
  }

}
