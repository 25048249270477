import EventEmitter from './EventEmitter';

export default class ScrollY extends EventEmitter {
  constructor() {
    super();

    this.enable = true;

    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this.scrollY = window.scrollY;
    this.section = 0;

    window.addEventListener('resize', () => {
      this.sizes.width = window.innerWidth;
      this.sizes.height = window.innerHeight;
      this.trigger('scrollResize');
    })


    window.addEventListener("scroll", () => {

      if (this.enable) {
        this.scrollY = window.scrollY;
        // calculating section
        const newSection = Math.round(scrollY / this.sizes.height);

        this.trigger('scrolling');

        // test intersection
        if (newSection != this.section) {
          this.section = newSection;
          this.trigger('scrollY');
        }
      }
    });
  }
}