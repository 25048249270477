<template>
  <div class="vrplayground">
    <Header
      :title="data.header.title"
      :type="data.header.type"
      :image="data.header.image"
      :resources="resources"
      :percent="percent"
      modelName="vrplaygroundModel"
      :scrollY="scrollY"
      :sizes="sizes"
    />
    <Goal
      :title="data.header.title"
      :description="data.goal.description"
      :websiteURL="data.goal.websiteURL"
      :scrollY="scrollY"
      :sizes="sizes"
    />
    <Challenge
      :description="data.challenge.description"
      :image="data.challenge.image"
      :scrollY="scrollY"
      :sizes="sizes"
    />
    <Solution
      :description="data.solution.description"
      :image="data.solution.image"
      :scrollY="scrollY"
      :sizes="sizes"
    />
    <Output
      :description="data.output.description"
      :image="data.output.image"
      :scrollY="scrollY"
      :sizes="sizes"
    />
    <Video
      :videoURL="data.output.videoURL"
      :video="data.video"
      :scrollY="scrollY"
      :sizes="sizes"
    />
    <Info
      :teamrole="data.info.teamrole"
      :material="data.info.material"
      :technology="data.info.technology"
      :year="data.info.year"
      :scrollY="scrollY"
      :sizes="sizes"
    />
    <PrevNext
      :prev="data.prevnext.prev"
      :next="data.prevnext.next"
      :scrollY="scrollY"
      :sizes="sizes"
    />
  </div>
</template>

<script>
import Header from "@/components/project/Header.vue";
import Goal from "@/components/project/Goal.vue";
import Challenge from "@/components/project/Challenge.vue";
import Solution from "@/components/project/Solution.vue";
import Output from "@/components/project/Output.vue";
import Video from "@/components/project/Video.vue";
import Info from "@/components/project/Info.vue";
import PrevNext from "@/components/project/PrevNext.vue";

import content from "@/assets/content.js";
import ScrollY from "@/webgl/utils/ScrollY.js";
import { onMounted, onUnmounted, ref } from "@vue/runtime-core";

export default {
  name: "VRPlayground",
  props: {
    resources: Object,
    percent: Number,
  },
  components: {
    Header,
    Goal,
    Challenge,
    Solution,
    Output,
    Video,
    Info,
    PrevNext,
  },
  setup() {
    const data = content.vrplayground;
    let scroll = new ScrollY();
    const scrollY = ref();
    const sizes = ref();

    onMounted(() => {
      sizes.value = scroll.sizes;
      scrollY.value = scroll.scrollY;

      scroll.on("scrollResize", () => {
        sizes.value = scroll.sizes;
      });

      scroll.on("scrolling", () => {
        scrollY.value = scroll.scrollY;
      });
    });

    onUnmounted(() => {
      scroll.enable = false;
    });

    return {
      data,
      scrollY,
      sizes,
    };
  },
};
</script>

<style lang='scss'>
</style>