<template>
  <footer ref="footerRef" class="footer" :id="addedID">
    <h5 class="author">website created by</h5>
    <h5 class="field">
      <router-link to="About"> Gerald Alvarez </router-link>
    </h5>

    <ul class="mediaList">
      <li>
        <h5>
          <a
            href="https://www.linkedin.com/in/gerald-alvarez-553499178/"
            target="_blank"
          >
            <i class="fab fa-linkedin cursor"></i>
          </a>
        </h5>
      </li>
      <li>
        <h5>
          <a href="https://twitter.com/genalv14" target="_blank">
            <i class="fab fa-twitter cursor"></i>
          </a>
        </h5>
      </li>
    </ul>
  </footer>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted, watchEffect } from "@vue/runtime-core";
// import { watchEffect } from "vue";

export default {
  name: "Footer",
  props: {
    viewLink: String,
    sectionID: Number,
    section: Number,
    addedID: String,
  },
  setup(props) {
    const footerRef = ref();

    onMounted(() => {
      watchEffect(() => {
        if (props.sectionID === props.section) {
          footerRef.value.classList.remove("anim-opacity-hide");
          footerRef.value.classList.add("anim-opacity-show");
        } else {
          footerRef.value.classList.remove("anim-opacity-show");
          footerRef.value.classList.add("anim-opacity-hide");
        }
      });
    });

    return { footerRef };
  },
};
</script>

<style lang="scss">
.footer {
  min-height: 100vh;
  @include size(100%, auto);
  @include flex-layout(column, center, center);

  .author {
    color: $color-highlight-primary;
    margin: 2rem 0 0 0;
    font-size: 1.4rem;
  }

  .field {
    color: $color-font-primary;
    font-size: 1.4rem;
    a {
      color: $color-font-primary;

      &:hover {
        color: $color-highlight-secondary;
      }
    }
  }

  .mediaList {
    @include flex-layout(row, center, center);

    li {
      margin: 2rem 1rem;
      h5 {
        color: $color-highlight-primary;
        font-size: 1.4rem;
      }
    }
  }
}

@media screen and (min-width: 414px) {
}
@media screen and (min-width: 768px) {
  // .footer {
  //   .author {
  //     font-size: 2rem;
  //   }

  //   .field {
  //     font-size: 2rem;
  //   }

  //   .mediaList {
  //     li {
  //       h5 {
  //         font-size: 2rem;
  //       }
  //     }
  //   }
  // }
}
@media screen and (min-width: 962px) {
  .footer {
    @include grid-layout(10, 6);

    .author {
      @include grid-item(2, 8, 5, 6);
      @include grid-self(end, start);
      margin: 0.2rem 0;
      padding: 0;
      font-size: 1.6rem;
    }

    .field {
      @include grid-item(2, 8, 6, 7);
      @include grid-self(start, start);
      margin: 0.2rem 0;
      padding: 0;
      font-size: 1.6rem;
    }

    .mediaList {
      @include grid-item(9, 10, 5, 7);
      @include grid-self(center, end);

      li {
        margin-left: 1rem;
        padding: 0;

        h5 {
          font-size: 1.6rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1920px) {
  .footer {
  }
}
</style>