<template>
  <div ref="curtainRef" class="curtain"></div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const curtainRef = ref();

    return {
      curtainRef,
    };
  },
};
</script>

<style lang='scss'>
.curtain {
  position: fixed;
  inset: 0 0 0 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  background-color: $color-highlight-primary;
}
</style>