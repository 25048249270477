<template>
  <!-- loader progress percentage -->
  <ProgressLoader :percent="percent" />

  <!-- Transition Curtain -->
  <Curtain ref="curtainRef" />

  <!-- nav menu -->
  <Nav />

  <!-- router views -->
  <router-view :resources="resources" :percent="percent" v-slot="{ Component }">
    <transition
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
      @after-leave="onAfterLeave"
      name="route"
      mode="out-in"
    >
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<script>
import ProgressLoader from "@/components/loaderTransition/ProgressLoader.vue";
import Curtain from "@/components/loaderTransition/Curtain.vue";
import Nav from "@/components/nav/Nav.vue";
import Resources from "@/webgl/utils/Resources.js";
import sources from "@/webgl/data/sources.js";
import { ref } from "@vue/reactivity";
import gsap from "gsap";

export default {
  components: {
    ProgressLoader,
    Curtain,
    Nav,
  },
  setup() {
    // instantiate the resources
    const resources = new Resources(sources);

    // progress loading percent. Make it reactive instead of sending the resource object.
    let percent = ref(0);

    // listen to resources loading
    resources.on("progress", () => {
      percent.value = Math.ceil((resources.loaded / resources.toLoad) * 100);
    });

    // -------------------------------
    // ref for curtain
    const curtainRef = ref();

    // ENTER - ANIMATION
    const onBeforeEnter = (el) => {
      el.style.opacity = 0;
      curtainRef.value.curtainRef.style.transformOrigin = "bottom";
      curtainRef.value.curtainRef.style.transform = "scaleY(1)";
    };
    const onEnter = (el, done) => {
      gsap.to(el, {
        duration: 1,
        opacity: 1,
      });
      gsap.to(curtainRef.value.curtainRef, {
        duration: 1,
        scaleY: 0,
        onComplete: () => {
          done();
        },
      });
    };
    const onAfterEnter = (el) => {
      el.style.opacity = 1;
    };

    // LEAVE - ANIMATION
    const onBeforeLeave = (el) => {
      el.style.opacity = 1;
      curtainRef.value.curtainRef.style.transformOrigin = "top";
      curtainRef.value.curtainRef.style.transform = "scaleY(0)";
    };
    const onLeave = (el, done) => {
      gsap.to(el, {
        duration: 1,
        opacity: 0,
      });
      gsap.to(curtainRef.value.curtainRef, {
        duration: 1,
        scaleY: 1,
        onComplete: () => {
          done();
        },
      });
    };
    const onAfterLeave = (el) => {
      el.style.opacity = 0;
    };

    return {
      resources,
      percent,
      curtainRef,
      onBeforeEnter,
      onEnter,
      onAfterEnter,
      onBeforeLeave,
      onLeave,
      onAfterLeave,
    };
  },
};
</script>

<style lang='scss'>
// // ENTER TRANSITION
// .route-enter-from {
//   opacity: 0;
// }
// .route-enter-active {
//   // transition: opacity 0.5s ease-out;
// }
// .route-enter-to {
//   opacity: 1;
// }

// // LEAVE TRANSITION
// .route-leave-from {
//   opacity: 1;
// }

// .route-leave-active {
//   // transition: opacity 0.5s ease-in;
// }

// .route-leave-to {
//   opacity: 0;
// }
</style>

