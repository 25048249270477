import * as THREE from 'three';

export default class Renderer {
  constructor(sizes, canvas, scene, camera) {
    this.sizes = sizes;
    this.canvas = canvas;
    this.scene = scene;
    this.camera = camera;

    this.setRenderer();
  }

  setRenderer() {
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true
    })
    this.instance.outputEncoding = THREE.sRGBEncoding;
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
  }


  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
  }

  update() {
    this.instance.render(this.scene, this.camera.instance)
  }
}