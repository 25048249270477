<template>
  <div class="scrollDown cursor">
    <a :href="targetID">
      <i ref="arrowDownIcon" class="fas fa-chevron-down"></i>
    </a>
  </div>
</template>

<script>
import { watchEffect, ref, onMounted } from "@vue/runtime-core";
export default {
  props: {
    targetID: String,
    section: Number,
  },
  setup(props) {
    const arrowDownIcon = ref();

    onMounted(() => {
      watchEffect(() => {
        if (props.section === 0) {
          // show the arrow
          arrowDownIcon.value.classList.remove("hide");
        } else {
          // hide the arrow
          arrowDownIcon.value.classList.add("hide");
        }
      });
    });

    return {
      arrowDownIcon,
    };
  },
};
</script>

<style lang='scss'>
.scrollDown {
  z-index: 200;
  display: inline-block;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  color: $color-highlight-primary;
  font-size: 4rem;

  i {
    display: block;
    text-align: center;
    opacity: 1;
    transition: 1s opacity ease-in-out;
  }

  i.hide {
    opacity: 0;
  }
}
</style>