<template>
  <div ref="sectionRef" id="videoSection" class="video">
    <div ref="videoRef" class="videoWrapper anim-opacity-hide">
      <iframe
        :src="video.scr"
        frameborder="0"
        allow="autoplay; fullscreen"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>



<script>
// https://www.javascripttutorial.net/javascript-dom/javascript-width-height/
import { onMounted, watchEffect, ref } from "@vue/runtime-core";
export default {
  props: {
    videoURL: String,
    video: Object,
    scrollY: Number,
    sizes: Object,
  },
  setup(props) {
    const sectionRef = ref();
    const section = ref(-1);
    let oldSection = -1;

    const videoRef = ref();

    onMounted(() => {
      // preset on mounted
      oldSection = section.value;
      // css property
      const elemCSS = window.getComputedStyle(sectionRef.value);

      watchEffect(() => {
        // prevent undefined value
        if (props.scrollY && props.sizes) {
          // section element properties
          const clientHeight = sectionRef.value.clientHeight;
          const offsetTop =
            sectionRef.value.offsetTop - parseInt(elemCSS.marginTop);
          const offsetBottom =
            sectionRef.value.offsetTop +
            clientHeight +
            parseInt(elemCSS.marginBottom);
          const sectionCenter = (offsetBottom - offsetTop) / 2;

          // test section's center is within bounds
          if (
            offsetTop + sectionCenter > props.scrollY &&
            offsetTop + sectionCenter < props.scrollY + props.sizes.height
          ) {
            section.value = 0;
          } else {
            // console.log("header is NOT in view");
            section.value = -1;
          }

          if (oldSection != section.value) {
            // assign new old section
            oldSection = section.value;

            if (section.value === 0) {
              // console.log("video is in view");
              videoRef.value.classList.remove("anim-opacity-hide");
              videoRef.value.classList.add("anim-opacity-show");
            } else {
              // console.log("video is NOT in view");
              videoRef.value.classList.remove("anim-opacity-show");
              videoRef.value.classList.add("anim-opacity-hide");
            }
          }
        }
      });
    });
    return { sectionRef, section, videoRef };
  },
};
</script>

<style lang='scss'>
.video {
  @include size(100%, auto);
  margin: 0 auto;
  padding: 1rem 5vw 5vw 5vw;

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0px;

    iframe {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (min-width: 414px) {
  .video {
    padding: 5vw 10vw;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 962px) {
}
</style>

