<template>
  <section ref="sectionRef" id="infoSection" class="info">
    <div ref="descRef" class="anim-opacity-hide">
      <div class="info-teamrole">
        <h5>Team & Roles</h5>
        <ul>
          <li v-for="item in teamrole" :key="item.name">
            {{ item.name }} : {{ item.role }}
          </li>
        </ul>
      </div>
      <div class="info-material">
        <h5>Material</h5>
        <ul>
          <li v-for="(item, index) in material" :key="index">
            <span v-if="index === material.length - 2">{{ item }}</span>
            <span v-else-if="index === material.length - 1">{{
              ` & ${item}`
            }}</span>
            <span v-else>{{ item }},</span>
          </li>
        </ul>
      </div>
      <div class="info-technology">
        <h5>Technology</h5>
        <ul>
          <li v-for="(item, index) in technology" :key="index">
            <span v-if="index === technology.length - 2">{{ item }}</span>
            <span v-else-if="index === technology.length - 1">{{
              ` & ${item}`
            }}</span>
            <span v-else>{{ item }},</span>
          </li>
        </ul>
      </div>
      <div class="info-year">
        <h5>Year</h5>
        <span class="">{{ year }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, watchEffect, ref } from "@vue/runtime-core";
export default {
  props: {
    teamrole: Array,
    material: Array,
    technology: Array,
    year: String,
    scrollY: Number,
    sizes: Object,
  },
  setup(props) {
    const sectionRef = ref();
    const section = ref(-1);
    let oldSection = -1;

    const descRef = ref();

    onMounted(() => {
      // preset on mounted
      oldSection = section.value;
      // css property
      const elemCSS = window.getComputedStyle(sectionRef.value);

      watchEffect(() => {
        // prevent undefined value
        if (props.scrollY && props.sizes) {
          // section element properties
          const clientHeight = sectionRef.value.clientHeight;
          const offsetTop =
            sectionRef.value.offsetTop - parseInt(elemCSS.marginTop);
          const offsetBottom =
            sectionRef.value.offsetTop +
            clientHeight +
            parseInt(elemCSS.marginBottom);
          const sectionCenter = (offsetBottom - offsetTop) / 2;

          // test section's center is within bounds
          if (
            offsetTop + sectionCenter > props.scrollY &&
            offsetTop + sectionCenter < props.scrollY + props.sizes.height
          ) {
            section.value = 0;
          } else {
            // console.log("header is NOT in view");
            section.value = -1;
          }

          if (oldSection != section.value) {
            // assign new old section
            oldSection = section.value;

            if (section.value === 0) {
              descRef.value.classList.remove("anim-opacity-hide");
              descRef.value.classList.add("anim-opacity-show");
            } else {
              descRef.value.classList.remove("anim-opacity-show");
              descRef.value.classList.add("anim-opacity-hide");
            }
          }
        }
      });
    });

    return { sectionRef, section, descRef };
  },
};
</script>

<style lang='scss'>
.info {
  @include size(100%, auto);
  @include flex-layout(column, center, flex-start);

  padding: 10vw 5vw;

  h5 {
    color: $color-highlight-primary;
    text-align: left;
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
  }

  li,
  span {
    color: $color-font-primary;
    font-size: 1rem;
    // padding: 0.3rem 0.6rem;
  }

  li {
    padding-right: 5px;
  }

  &-teamrole {
    width: 100%;
  }

  &-material {
    width: 100%;
    margin: 1rem 0 0 0;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-technology {
    width: 100%;
    margin: 1rem 0 0 0;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-year {
    width: 100%;
    margin: 1rem 0 0 0;
    span {
      display: block;
    }
  }
}

@media screen and (min-width: 414px) {
  .info {
    padding: 10vw;

    // li,
    // span {
    //   font-size: 1.2rem;
    // }
  }
}
@media screen and (min-width: 768px) {
  .info {
    padding: 5vw 10vw;
    // padding-bottom: 10vw;
  }
}
@media screen and (min-width: 962px) {
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1920px) {
}
</style>