const videoType = {
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo'
}

export default {
  affablechair: {
    header: {
      title: 'Affable Chair',
      type: 'Therapeutic Device Chair',
      image: 'affablechair/img1.jpg',
    },
    goal: {
      description: 'Affable chair is a therapeutic chair concept designed to correct the sitting posture and to promote periodical breaks. We tend to sit for a heavy amount of time focused on gaming or working. Sometimes we forget to take a break which leads to incorrect postures. The chair is designed to notify the sitter when these matters occur. It is a device that monitors the user\'s well-being and strongly notifies the sitter when to get up. ',
      websiteURL: '',
    },
    challenge: {
      description: 'Disrupting and expelling the sitter from their comfort zone is troublesome if it is done incorrectly; a forceful or sudden approach might be hurtful to the user instead of helpful. However, a weak and delicate approach might also be ineffective in convincing the sitter. There must be a balance between coerciveness and encouragement for the persuasion to be successful.',
      image: 'affablechair/img2.jpg',
    },
    solution: {
      description: 'The chair is equipped with pressure sensors to detect the sitter\'s posture and three distinct electronic outputs to alert a bad posture and to persuade a recess. It is equipped with a piezoelectric speaker for a primary output, two micro-vibration motors for a secondary output, and two heat pads for a final output. In addition, these outputs are configured to give feedback according to the user\'s behavior. Essentially, the chair is loaded with powerful experiences to shake the user out of its comfort zone.',
      image: 'affablechair/img3.jpg',
    },
    output: {
      description: 'Affable Chair delivers \'friendly\' reminders to the users to be active. It notifies the user to take a break or to adjust the sitting posture with three kinds of non-harmful outputs. This is a companion device that fixes bad habits and cares for positive changes.',
      image: 'affablechair/img4.jpg',
      videoURL: 'https://player.vimeo.com/video/408149849?h=cf61dace26',
    },
    video: {
      scr: "https://player.vimeo.com/video/408149849",
      type: videoType.VIMEO,
    },
    info: {
      teamrole: [
        { name: 'Gerald Alvarez', role: 'Gerald A: Circuit Designer, Coder, Sewing, Project Assembler, Concept Designer' },
      ],
      material: [
        "Ergonomic Recycled Chair",
        "Nylon Fabric",
        "Pressure-sensitive Conductive Sheet",
        "Conductive Thread",
        "Micro-vibration Motors",
        "Heat Pads",
        "Resistors",
        "Transistors",
        "Diodes",
        "Piezo Speaker",
        "Wires Cables",
        "Breadboard",
        "Arduino Micro-controller",
      ],
      technology: [
        'c++ (Arduino)',
        'Fritzing'
      ],
      year: 'Fall 2017'
    },
    prevnext: {
      prev: {
        title: 'VR Playground',
        type: 'VR Development',
        viewLink: 'VRPlayground',
        image: 'vrplayground/img1.jpg',
      },
      next: {
        title: 'Everewaste',
        type: 'Web Experience',
        viewLink: 'EverEWaste',
        image: 'everewaste/img1.jpg',
      },
    }
  },
  concrheat: {
    header: {
      title: 'ConcrHeat',
      type: 'Interactive Art Installation',
      image: 'concrheat/img1.jpg',
    },
    goal: {
      description: 'ConcrHeat is an interactive art installation that illustrates the issue of "Urban Heat Island" in Montreal. This issue occurs when a city experiences warmer temperatures due to concrete infrastructures that absorb and store summer\'s heat. The purpose of this project is to expose the negative effect of this phenomenon and raise awareness.',
      websiteURL: '',
    },
    challenge: {
      description: 'Our team wanted to convey the message dynamically and tangibly. However, designing a convincing and effective method to portray an intangible and invisible phenomenon, through traditional means, was difficult. Delivering a visual and auditory message was insufficient and unimpactful. We set ourselves to create something playful and illustrative to grasp the essence of the subject and to intrigue the viewers.',
      image: 'concrheat/img2.jpg',
    },
    solution: {
      description: 'On top of digitals outputs, such as projection mapping, we added Nichrome Wires in conjunction with Thermochromic Paint to achieve the tangible and analogue outputs. The nichrome wire generates the heat when an electric current passes through, affecting the thermochromic paint to change its color. As a result, we were utilizing analog and digital media to create dynamic and tangible outputs that perfectly illustrate the intention of the art piece.',
      image: 'concrheat/img3.jpg',
    },
    output: {
      description: 'Users were interacting with the artwork beyond the scope of the intended design. People were placing their hands long enough on the maquette to create their heat print which dictates their own presence in the art piece. Their curiosity and playfulness extends the purpose and presentation of the project. This validates that human activities play a huge role in the Urban Heat Island.',
      image: 'concrheat/img4.jpg',
      videoURL: 'https://player.vimeo.com/video/304852775',
    },
    video: {
      scr: 'https://player.vimeo.com/video/304852775',
      type: videoType.VIMEO,
    },
    info: {
      teamrole: [
        { name: 'Gerald Alvarez', role: 'Coder, Circuit Design, Construction Assembler & Interaction Design ' },
        { name: 'Steven Moore-Vountas', role: '3d modeler, Coder, Construction Assembler & Interaction Design' },
        { name: 'Rochelle Panganiban', role: 'Layout designer, Coder, Construction Assembler & Interaction Design' },
        { name: 'Maedeh Sharifi', role: 'Sound design, coder, Construction Assembler &Interaction Design' },
        { name: 'Julie Babineau', role: 'Human resources, Construction Assembler & Interaction Design' },
      ],
      material: [
        "Plywood ",
        "Thermochromic Pigment ",
        "Ccrylic Paint",
        'Photo Cell Sensors',
        "Nichrome Wires",
        "Resistors",
        "Power Transistors",
        "8-bit Shift Registers",
        "Wire Cables",
        "Arduino Mega Micro-controller",
      ],
      technology: [
        'c++ (Arduino)',
        'java (Processing)',
        'Adobe Illustrator',
        'Adobe Audition'
      ],
      year: 'Fall 2018'
    },
    prevnext: {
      prev: {
        title: 'Everewaste',
        type: 'Web Experience',
        viewLink: 'EverEWaste',
        image: 'everewaste/img1.jpg',
      },
      next: {
        title: 'Moribund',
        type: 'VR Experience',
        viewLink: 'Moribund',
        image: 'moribund/img1.jpg',
      },
    }
  },
  everewaste: {
    header: {
      title: 'Everewaste',
      type: 'Web Experience',
      image: 'everewaste/img1.jpg',
    },
    goal: {
      description: `Everewaste is an interactive web experience that explores the horrible nature of e-waste. The usage of electronic gadgets is increasing every day. Consequently, the electronic waste is piling up somewhere in the world. We take for granted the affordance of these electronics. Yet, when these become obsolete; we forgo the responsibility of the aftermath. We undermine or ignore the gravity and consequences of e-waste.`,
      websiteURL: 'https://webgl-everewaste-project.vercel.app/',
    },
    challenge: {
      description: 'The subject is too vast to cover in a single web project. More information does not equate clarity. The challenge comes on the delivery of the messages and intentions in an intuitive and compressive method. This project needs to attract the user, therefore it needs the elements of interactivity, discovery and learning.',
      image: 'everewaste/img2.jpg',
    },
    solution: {
      description: 'Firstly, the integration of 3D texts (words representing the topics) allows the user to grasp the intended message. These texts are essentially several topics to inform and to reflect on. Secondly, the integration of the audio clips adds context and narrative to the texts. The user will be given sets of audio clips per topic. The audio clips are fragments of brilliant messages pertaining to the associated topic. Lastly, the participation of the user creates the crux of the experience. The mouse click input of the participant triggers the domino effect. As a result, it demonstrates the mechanics and outcome of the experience.',
      image: 'everewaste/img3.jpg',
    },
    output: {
      description: 'The project is a simulacrum of the issue. The user is given five interactable texts to play and to discover its obsolescence. Essentially, the core mechanic juxtaposes the discardment of obsolete gadgets. So, the outcome of the experience reflects on the ugliness of e-waste as well as on the environmental disaster. The user is set as the subject and this experience is set as the instrument to reenact the process of e-waste.',
      image: 'everewaste/img4.jpg',
      videoURL: 'https://player.vimeo.com/video/408149849?h=cf61dace26'
    },
    video: {
      scr: "https://player.vimeo.com/video/682723580",
      type: videoType.VIMEO,
    },
    info: {
      teamrole: [
        { name: 'Gerald Alvarez', role: 'coder, designer, 3D modeler' },
      ],
      material: [
        'HTML', "CSS", "Javascript"
      ],
      technology: [
        'Blender',
        'ThreeJS',
        'Audacity',
      ],
      year: 'Summer 2021'
    },
    prevnext: {
      prev: {
        title: 'Affable Chair',
        type: 'Therapeutic Device Chair',
        viewLink: 'AffableChair',
        image: 'affablechair/img1.jpg',
      },
      next: {
        title: 'ConcrHeat',
        type: 'Art Installation',
        viewLink: 'ConcrHeat',
        image: 'concrheat/img1.jpg',
      },
    }
  },
  moribund: {
    header: {
      title: 'Moribund',
      type: 'VR Experience',
      image: 'moribund/img1.jpg',
    },
    goal: {
      description: 'Moribund is an experiential VR adventure game where the user plays the role of the main character. The game focuses on exploring virtual space and interacting with the characters to discover the story revolving around the notion of the five stages of grief: denial, anger, bargaining, depression, and acceptance.',
      websiteURL: '',
    },
    challenge: {
      description: 'Consistent storytelling and settings are crucial for the game\'s development and player\'s gameplay. In our case, Moribund explores five themes to illustrate the notion of grief in our own interpretation and story. The concern is how to integrate these themes without overwhelming the game flow and the user experience.',
      image: 'moribund/img2.jpg',
    },
    solution: {
      description: 'Instead of primarily focusing on the five stages of grief for the story, we used it as a structural support for the narrative by weaving them into the settings. We portrayed the themes by decorating the environment and atmosphere using color theory, music background, and NPC dialogues.',
      image: 'moribund/img3.jpg',
    },
    output: {
      description: 'The settings emphasize the subject of this project. The player traverses the environment which exhibits a surrealistic sensation and appeals to uncover the true nature of an adventure game.',
      image: 'moribund/img4.jpg',
      videoURL: 'https://www.youtube.com/embed/wB1jgNY6aZw'
    },
    video: {
      scr: 'https://www.youtube.com/embed/wB1jgNY6aZw',
      type: videoType.YOUTUBE,
    },
    info: {
      teamrole: [
        { name: 'Gerald', role: 'Coder, 3D modeler, Game Design & Voice Actor ' },
        { name: 'Kathleen', role: '3D Modeler, Model Texture Designer, Music Composer, Game Design & Voice Actor ' },
        { name: 'Sabrina', role: 'Model Texture Design, Website Development & Game Design' }
      ],
      material: [
        "VR Headset (HTC Vive)",
      ],
      technology: [
        "Blender",
        "Unity",
        "Audacity",
        "Mixcraft8",
        "Adobe Audition",
        "SteamVR",
        "Photoshop"
      ],
      year: 'Fall 2019'
    },
    prevnext: {
      prev: {
        title: 'ConcrHeat',
        type: 'Art Installation',
        viewLink: 'ConcrHeat',
        image: 'concrheat/img1.jpg',
      },
      next: {
        title: 'Psychoreceiver',
        type: 'Interactive Short Movie',
        viewLink: 'Psychoreceiver',
        image: 'psychoreceiver/img1.jpg',
      },
    }
  },
  psychoreceiver: {
    header: {
      title: 'Pyschoreceiver',
      type: 'Interactive Short Movie',
      image: 'psychoreceiver/img1.jpg',
    },
    goal: {
      description: 'Psychoreceiver is a biometric interactive short movie. It revolves around the idea of using the emotional response of the user to determine the outcome of the movie. The project is inspired by the Film, \'Black Mirror: Bandersnatch,\' in which the users were able to make a selective path for the story of the film. For this project, we used biometric sensors to determine a binary choice for the short movie.',
      websiteURL: '',
    },
    challenge: {
      description: 'Individual\'s emotional response is unique and subjective. Interpreting and quantifying these biometrics data are unreliable without a basis of reference. It is crucial that the aggregated data is tailored to the user\'s emotional capacity.',
      image: 'psychoreceiver/img2.jpg',
    },
    solution: {
      description: 'We utilized a Pulse Sensor to detect the changes in the heart beats and a Galvanic Skin Response (GSR) to detect the changes in the sweat gland activities. The combination of these sensors allowed us to extrapolate the output data to learn and grasp the user\'s emotional response.',
      image: 'psychoreceiver/img3.jpg',
    },
    output: {
      description: 'We were able to cross-reference the output data to evaluate the highs and lows of the emotional range. Essentially, we had to calculate the median value for every watcher and used it as the basis of reference. With the establishment of this emotional threshold, we assembled a formula to detect the level of emotional response.',
      image: 'psychoreceiver/img4.jpg',
      videoURL: 'https://player.vimeo.com/video/386623574?h=7c8428a64b'
    },
    video: {
      scr: "https://player.vimeo.com/video/408290612",
      type: videoType.VIMEO,
    },
    info: {
      teamrole: [
        { name: 'Gerald Alvarez', role: 'Programmer & Sensors Device Developer' },
        { name: 'Matthew Vaccariello: ', role: 'Actor & Video Editor' },
        { name: 'Fadel Ragheb', role: 'Actor & Video Editor' },
        { name: 'Anthony Delorme', role: 'Director & Actor' }
      ],
      material: [
        "Pulse Sensor",
        "GSR Sensor",
        "Plywood",
        "Acrylic",
        "Arduino Micro-controller",
        "GoPro Camera",
        "H1 Zoom Recorder"
      ],
      technology: [
        "Adode Premiere ",
        "Adobe After Effect",
        "C++ (Arduino)",
        "Max 7"
      ],
      year: 'Winter 2019'
    },
    prevnext: {
      prev: {
        title: 'Moribund',
        type: 'VR Experience',
        viewLink: 'Moribund',
        image: 'moribund/img1.jpg',
      },
      next: {
        title: 'VR Playground',
        type: 'VR Development',
        viewLink: 'VRPlayground',
        image: 'vrplayground/img1.jpg',
      },
    }
  },
  vrplayground: {
    header: {
      title: 'VR Playground',
      type: 'VR Development',
      image: 'vrplayground/img1.jpg',
    },
    goal: {
      description: 'VR Playground is the result of my work from a collaboration with a Master Design Student, Seyed M, Tabatabaei. The goal was to help him code the VR mechanics and shader animations for his master project "Remediation of Pardeh Khani: Scale and its Affective Effect in VR."',
      websiteURL: '',
    },
    challenge: {
      description: 'The project utilizes Unity Game Engine to build the environment, SteamVR Plugin to handle the VR Medium, and Tilt Brush plugin to display 3D visual artworks. The concern is the static functionality - mechanics for SteamVR and animation for TiltBrush - of these technologies. These offer no option to create new functionality. Therefore, a developer needs to work around it.',
      image: 'vrplayground/img2.jpg',
    },
    solution: {
      description: 'Coding a plugin such as SteamVR and Tilt Brush from scratch is time-consuming and laborious, and it is beyond the scope of the collaboration\'s time frame and my skill sets. Since the project is for an educational and non-commercial purpose, modifying the source code and implementing many new functionalities is an answer.',
      image: 'vrplayground/img3.jpg',
    },
    output: {
      description: 'Modifying the SteamVR and Tilt Brush plugins was practically essential. As a result, new mechanics were implemented such as the new gliding locomotion mechanic, the player scaling mechanics, and the rotating platform mechanic, in a short time. Also, we achieved our goal of modifying many of the selected shaders in TiltBrush to have dynamic animation with controllable properties.',
      image: 'vrplayground/img4.jpg',
      videoURL: 'https://player.vimeo.com/video/347813062'
    },
    video: {
      scr: "https://player.vimeo.com/video/408248097",
      type: videoType.VIMEO,
    },
    info: {
      teamrole: [
        { name: 'Gerald A', role: 'Coder, Research and Creation, Tester' },
        { name: 'Seyed Tabatabaei', role: 'VR Mechanics Designer, Research and Creation, Tester' },
      ],
      material: [
        "VR Headset (HTC Vive)",
      ],
      technology: [
        "Unity",
        "SteamVR",
        "TiltBrush"
      ],
      year: 'Summer 2019'
    },
    prevnext: {
      prev: {
        title: 'Psychoreceiver',
        type: 'Interactive Short Movie',
        viewLink: 'Psychoreceiver',
        image: 'psychoreceiver/img1.jpg',
      },
      next: {
        title: 'Affable Chair',
        type: 'Therapeutic Device Chair',
        viewLink: 'AffableChair',
        image: 'affablechair/img1.jpg',
      },
    }
  }
}