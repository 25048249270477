<template>
  <section class="hero" :id="addedID">
    <div ref="contentRef" class="content">
      <h2>Hello, I am Gerald.</h2>
      <p>
        I am a Montreal based multimedia developer with the intention of
        creating meaningful and playful projects. I like to work with tangible
        and digital media, and tinker with diverse technologies.
      </p>

      <div class="callButton">
        <!-- BUTTON? call to action. Must of pop off -->
        <span class="cursor"
          ><router-link :to="{ name: viewLink }">Get to know me</router-link>
        </span>
      </div>
    </div>
  </section>
  <ScrollDown targetID="#everewasteHome" :section="section" />
</template>

<script>
import ScrollDown from "@/components/utilities/ScrollDown.vue";
import { onMounted, watchEffect, ref } from "@vue/runtime-core";
export default {
  name: "Hero",
  props: {
    viewLink: String,
    sectionID: Number,
    section: Number,
    addedID: String,
  },
  components: {
    ScrollDown,
  },
  setup(props) {
    const contentRef = ref();

    onMounted(() => {
      watchEffect(() => {
        if (props.sectionID === props.section) {
          contentRef.value.classList.remove("anim-opacity-hide");
          contentRef.value.classList.add("anim-opacity-show");
        } else {
          contentRef.value.classList.remove("anim-opacity-show");
          contentRef.value.classList.add("anim-opacity-hide");
        }
      });
    });

    return { contentRef };
  },
};
</script>

<style lang='scss'>
// starting 320
.hero {
  @include size(100%, 100vh);
  @include flex-layout(column, center, center);

  padding: 0 6vw;

  .content {
    @include flex-layout(column, center, flex-start);

    padding: 4vw;
    border-radius: 10px;
    // background: rgba($color: #000000, $alpha: 0.1);
    h2 {
      color: $color-highlight-primary;
      font-size: 2.5rem;
      text-align: left;
      margin: 0 0 1rem 0;
      padding: 0;
    }

    p {
      color: $color-font-primary;
      font-size: 1rem;
      margin: 0.5rem 0;
      padding: 0;
    }

    .callButton {
      width: 100%;
      @include flex-layout(column, center, flex-start);
      margin: 1rem 0 0 0;
      padding: 0;

      span {
        a {
          color: $color-font-primary;
          font-size: 1rem;
          letter-spacing: 1px;

          &.router-link-exact-active {
            color: $color-font-primary;
          }

          &:hover {
            color: $color-highlight-secondary;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 414px) {
}
@media screen and (min-width: 768px) {
  // .hero {
  //   .content {
  //     h2 {
  //       font-size: 2.4rem;
  //     }

  //     p {
  //       font-size: 1.4rem;
  //     }

  //     .callButton {
  //       span {
  //         a {
  //           font-size: 1.4rem;
  //         }
  //       }
  //     }
  //   }
  // }
}
@media screen and (min-width: 1366px) {
  .hero {
    align-items: flex-start;
    .content {
      width: 60%;

      h2 {
        font-size: 4rem;
      }
      p {
        font-size: 1.4rem;
      }

      .callButton {
        span {
          a {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  // .hero {
  //   .content {
  //     width: 60%;

  //     h2 {
  //       font-size: 6rem;
  //     }
  //     p {
  //       font-size: 1.8rem;
  //     }
  //   }
  // }
}
</style>