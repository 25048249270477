<template>
  <section class="projectItem" :id="addedID">
    <div class="titleWrapper" ref="titleWrapperRef">
      <span class="titleBG"></span>
      <div class="titleClipBox">
        <div
          ref="titleAnimRef"
          class="titleContent anim-slide anim-slide-up-hide"
        >
          <h1 class="cursor">
            <router-link :to="{ name: viewLink }">
              {{ name }}
            </router-link>
          </h1>
        </div>
      </div>
    </div>

    <div class="subtitleWrapper">
      <h4 ref="subtitleAnimRef" class="anim-slide anim-slide-down-hide">
        {{ type }}
      </h4>
    </div>
  </section>
</template>

<script>
import { onMounted, watchEffect, ref } from "@vue/runtime-core";
export default {
  name: "ProjectItem",
  props: {
    viewLink: String,
    name: String,
    type: String,
    sectionID: Number,
    section: Number,
    width: Number,
    addedID: String,
  },

  setup(props) {
    const titleWrapperRef = ref();
    const titleAnimRef = ref();
    const subtitleAnimRef = ref();

    onMounted(() => {
      watchEffect(() => {
        if (props.sectionID === props.section) {
          titleAnimRef.value.classList.remove("anim-slide-up-hide");
          titleAnimRef.value.classList.add("anim-slide-up-show");
          subtitleAnimRef.value.classList.remove("anim-slide-down-hide");
          subtitleAnimRef.value.classList.add("anim-slide-down-show");
        } else {
          titleAnimRef.value.classList.remove("anim-slide-up-show");
          titleAnimRef.value.classList.add("anim-slide-up-hide");
          subtitleAnimRef.value.classList.remove("anim-slide-down-show");
          subtitleAnimRef.value.classList.add("anim-slide-down-hide");
        }
      });
    });

    return { titleWrapperRef, titleAnimRef, subtitleAnimRef };
  },
};
</script>

<style lang='scss'>
.projectItem {
  @include size(100%, 100vh);
  @include flex-layout(column, center, center);
  position: relative;

  .titleWrapper {
    position: relative;

    span {
      position: absolute;
      top: 0;
      left: 0;
    }

    .titleClipBox {
      padding-bottom: 0.5vw;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

      .titleContent {
        position: relative;
        width: 100%;
        // same height or greater as font-size
        height: 100%;

        h1 {
          font-size: 10vw;

          a {
            font-family: "Barlow", sans-serif;
            color: $color-font-primary;
            &.router-link-exact-active {
              color: $color-font-primary;
            }

            &:hover {
              color: $color-highlight-secondary;
            }
          }
        }
      }
    }
  }

  .subtitleWrapper {
    // need for the anim
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    position: relative;
    width: 100%;
    height: 4vw;

    h4 {
      color: $color-font-primary;
      font-size: 2.8vw;
      font-family: "Manrope", sans-serif;
      font-weight: 300;
    }
  }

  // anim class
  .anim-slide {
    // need for the anim
    position: absolute;
    top: 0;
    left: 50%;
  }

  .anim-slide-up-hide {
    transform: translate(-50%, 100%);
    transition: transform 1s ease;
  }

  .anim-slide-down-hide {
    transform: translate(-50%, -100%);
    transition: transform 1s ease;
  }

  .anim-slide-up-show,
  .anim-slide-down-show {
    transform: translate(-50%, 0%);
    transition: transform 1s ease;
  }
}

@media screen and (min-width: 414px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1366px) {
  .projectItem {
    .titleWrapper {
      &:hover span {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }

      span {
        z-index: -100;
        position: absolute;
        top: 0;
        left: -7%;
        width: 110%;
        height: 100%;
        display: block;
        background-color: $color-highlight-primary;
        transform: rotateZ(2deg);

        -webkit-transition: all 1s cubic-bezier(0.51, 0.03, 0.29, 0.96);
        transition: all 1s cubic-bezier(0.51, 0.03, 0.29, 0.96);

        // clip path
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      }

      .titleClipBox {
        .titleContent {
          h1 {
            a {
              color: $color-font-primary;
              &.router-link-exact-active {
                color: $color-font-primary;
              }

              &:hover {
                color: $color-font-primary;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1920px) {
}
</style>