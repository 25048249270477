export default [
  {
    name: 'model',
    type: 'gltfModel',
    path: '/models/models.glb'
  },
  {
    name: 'affablechairModel',
    type: 'gltfModel',
    path: '/models/affablechair-model.glb'
  },
  {
    name: 'concrheatModel',
    type: 'gltfModel',
    path: '/models/concrheat-model.glb'
  },
  {
    name: 'everewasteModel',
    type: 'gltfModel',
    path: '/models/everewaste-model.glb'
  },
  {
    name: 'moribundModel',
    type: 'gltfModel',
    path: '/models/moribund-model.glb'
  },
  {
    name: 'psychoreceiverModel',
    type: 'gltfModel',
    path: '/models/psychoreceiver-model.glb'
  },
  {
    name: 'vrplaygroundModel',
    type: 'gltfModel',
    path: '/models/vrplayground-model.glb'
  },
  {
    name: 'affablechairTexture',
    type: 'texture',
    path: '/textures/affablechair-texture.jpg'
  },
  {
    name: 'concrheatTexture',
    type: 'texture',
    path: '/textures/concrheat-texture.jpg'
  },
  {
    name: 'everewasteTexture',
    type: 'texture',
    path: '/textures/everewaste-texture.jpg'
  },
  {
    name: 'moribundTexture',
    type: 'texture',
    path: '/textures/moribund-texture.jpg'
  },
  {
    name: 'psychoreceiverTexture',
    type: 'texture',
    path: '/textures/psychoreceiver-texture.jpg'
  },
  {
    name: 'vrplaygroundTexture',
    type: 'texture',
    path: '/textures/vrplayground-texture.jpg'
  }
]