import * as THREE from 'three';
import Debug from '../utils/Debug.js'
import Sizes from '../utils/Sizes.js'
import Time from '../utils/Time.js'
import Camera from '../Camera.js'
import Renderer from '../Renderer.js';
import Models from '../Models'
import ParticleSystem from '../particles/ParticleSystem.js';

export default class HomeWorld {
  constructor() {
    // set them externally
    this.canvas = null;
    this.resources = null;
  }


  init() {

    this.debug = new Debug();
    this.sizes = new Sizes();
    this.time = new Time();
    this.scene = new THREE.Scene();
    this.camera = new Camera(this.debug, this.sizes, this.canvas, this.scene);
    this.renderer = new Renderer(this.sizes, this.canvas, this.scene, this.camera);
    this.models = new Models(this.scene, this.resources, this.time);
    this.particles = new ParticleSystem(this.scene, this.time);


    // resize event
    this.sizes.on('resize', () => {
      this.resize();
    })

    // time tick event
    this.time.on('tick', () => {
      this.update();
    })
  }


  dispose() {
    this.models.dispose();
    this.particles.dispose();
  }

  resize() {
    // this.models.resize();
    this.particles.resize();
    this.camera.resize();
    this.renderer.resize();
  }

  update() {
    // this.models.update();
    this.particles.update();
    this.camera.update();
    this.renderer.update();
  }
}